export const PublicRoutes ={
    HOME: 'home',
    NOTICIAS:'noticias',
    NOSOTROS:'nosotros',
    BENEFICIOS:'beneficios',
    AYUDA:'ayuda',
    LOGIN:'login'
};

export const PrivateRoutes = {
    DASHBOARD:'dashboard',

    SHOWPROFESORES:'showprofesores',
    CREATEPROFESORES:'crearprofesor',

    SHOWCLASES:'showclases',
    AÑADIRCLASE:'añadirclase',

    SHOWHORARIOS:'showhorarios',
    CREARHORA:'crearhora',

    ADDBITACORA:'addbitacora',
    VERBITACORAS:'verbitacoras'

}
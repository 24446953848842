
import './App.css';

import Home from "./Pages/Home.jsx"
import Nosotros from './Pages/nosotros';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Beneficios from './Pages/beneficios';
import FooterV2 from './Components/footerV2';
import Ayuda from './Pages/Ayuda';
import Noticias from './Pages/Noticias';
import NoticiaSpecific from "./Components/noticias/NoticiaSpecific";
import RoutesWithNotFound from './utilities/notFoundRoutes.utility';
import { PrivateRoutes, PublicRoutes } from './routes/routes';
import Login from './Components/login/login';
import AuthLogin from './guards/authLogin';
import Private from './Pages/private/Private';
import Profesores from './Pages/profesores';
import Horarios from './Pages/Horarios';
import Convenios from './Pages/convenios.jsx';


function App() {
  return (
    <div className="App">
      <Router>
        <RoutesWithNotFound>
          
            <Route path="/" element={<Home />} />

            <Route path="/noticias" element={<Noticias />} />
            <Route path="/noticias/:id" element={<NoticiaSpecific />} />

            <Route path="/clases/profesores" element={<Profesores/>} />
            <Route path="/clases/horarios" element={<Horarios/>} />
 
            <Route path="/nosotros" element={<Nosotros />} />

            <Route path='/beneficios/beneficios' element={<Beneficios />} />
            <Route path='/beneficios/convenios' element={<Convenios />} />

            <Route path='/ayuda' element={<Ayuda />} />
          
            <Route path='/tests' element={<Ayuda />} />

            <Route path='/tests' element={<Ayuda />} />

            <Route path={`/${PublicRoutes.LOGIN}`} element={<Login />} />

            <Route element={<AuthLogin/>}>
              <Route path={`${PrivateRoutes.DASHBOARD}/*`} element={<Private />}/>
            </Route>

          

          
        </RoutesWithNotFound>
      </Router>
      <FooterV2 />
      {/*}
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>*/}
    </div>
  );
}

export default App;

import TestHeader from "../Components/Header/headerV2";

import ReglamentoPiscina from "../assets/PDF/REGLAMENTO INTERNO DE PISCINA.pdf"

export default function Ayuda(){
    return(
        <>
        <div  className="MyBackground">
            <TestHeader/>
            
                <div className="container" style={{backgroundColor:"#fdfdfd", minHeight:"100vh", paddingTop:"3vh",paddingLeft:"3rem", paddingBottom:"2rem", textAlign:"left"}}>
                    <span style={{textAlign:"left", padding:"10px"}}>
                    <h3 >
                        Reglamentos: 
                    </h3>
                    </span>
                        <li style={{marginLeft:"3rem"}}><a href={ReglamentoPiscina} target="_blank" rel="noreferrer"> Reglamento de Piscina </a></li>
                    
                </div>
            </div>
        </>
    )
}
import FooterV2 from "../Components/footerV2";
import Header from "../Components/Header/headerV2";

import frontis from "../assets/frontisParque-1920x870.jpg"

export default function Nosotros(){

    console.log(window.location.href.toLowerCase().includes("nosotros"))


    return(
        <div className="MyBackground">
            <Header></Header>
            <div className="container" style={{ backgroundColor: "aliceblue", minHeight: "100vh" }}>
                {/** Parque Austral SAC fue fundada en el año 1998 en la ciudad de Coyhaique, 
                 * desde un inicio los socios fundadores vieron el beneficio que un Complejo Deportivo de 2.500 metros 
                 * cuadrados aportaría a la comunidad de la Región prestando sus servicios deportivos y salud. 
                 * Con 25 años en el rubro de la actividad deportiva, Complejo Deportivo Parque Austral, posee entre 
                 * sus dependencias Piscina Techada Temperada, Salón de Fitness, Salón de Cycling, Sala de Musculación 
                 * y Máquinas, Multicancha Techada y recientemente amplió sus servicios cómo Centro de Salud 
                 * Parque Austral contando con profesionales de la salud en las áreas de Medicina, Nutrición, 
                 * Acupuntura, Kinesiología y Masoterapia. Con estos servicios Complejo Deportivo Parque Austral 
                 * brinda a la comunidad un espacio familiar de vida saludable y actividad deportiva.  
 */}
                        
                <div className="row" style={{paddingTop: "3rem", paddingBottom: "3rem"}}>
                    <div className="col-md-6">
                        <img src={frontis} alt="parque-austral" border="0" style={{width: "100%"}}/>
                    </div>
                    <div className="col-md-6" >
                    <h1>Nosotros</h1>
                        <p style={{ textAlign: "justify", overflowWrap: 'break-word', whiteSpace: "pre-wrap" }}>Parque Austral SAC fue fundada en el año 1998 en la ciudad de Coyhaique,
                            desde un inicio los socios fundadores vieron el beneficio que un Complejo Deportivo de 2.500 metros
                            cuadrados aportaría a la comunidad de la Región prestando sus servicios deportivos y salud.
                            Con 25 años en el rubro de la actividad deportiva, Complejo Deportivo Parque Austral, posee entre
                            sus dependencias Piscina Techada Temperada, Salón de Fitness, Salón de Cycling, Sala de Musculación
                            y Máquinas, Multicancha Techada y recientemente amplió sus servicios cómo Centro de Salud
                            Parque Austral contando con profesionales de la salud en las áreas de Medicina, Nutrición,
                            Acupuntura, Kinesiología y Masoterapia. Con estos servicios Complejo Deportivo Parque Austral
                            brinda a la comunidad un espacio familiar de vida saludable y actividad deportiva.
                        </p>

                    </div>
            </div>
            
            </div>

            
        </div>
    )
}
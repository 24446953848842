import React from 'react'
import { Modal, Button, ToastContainer } from 'react-bootstrap'
import { toast } from 'react-toastify'
import axios from 'axios'

import { AXIOS_HOST } from '../../../config/axios.config'
import { obtainLocalStorageItem } from '../../../utilities/localStorage.utility'

function StatusProfesor({ body, status, id, show, onHide, callback, title }) {

    const activar = async () => {
        await axios.patch(`${AXIOS_HOST}/profes/enable`, { id: id, token: `${obtainLocalStorageItem("token")}` }).then(res => {
            console.log(res)
            callback({ continua: true, enabled: true, message: "Profesor Habilitado" })
        }).catch(err => {
            console.log(err)
            callback({ continua: false })
            toast.error("Error al habilitar profesor")
        })
    }

    const desactivar = async () => {
        await axios.delete(`${AXIOS_HOST}/profes/disable`, { data: { id: id, token: `${obtainLocalStorageItem("token")}` } }).then(res => {
            console.log(res)
            callback({ continua: true, enabled: false, message: ("Profesor deshabilitado") })
        }).catch(err => {
            console.log(err)
            callback({ continua: false })
            toast.error("Error al deshabilitar profesor")
        })
    }

    const selector = () => {
        switch (status) {
            case 0:
                activar()
                break;
            case 1:
                desactivar()
                break;
            default:
                callback({ continua: false })
                break;
        }
    }

    return (
        <>
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{body}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => callback({ continua: false })}>Cancelar</Button>
                    {status ?
                        <button className="btn btn-danger" onClick={() => selector()}>Desactivar</button> :
                        <button className="btn btn-success" onClick={() => selector()}>Activar</button>
                    }
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </>
    )
}

export default StatusProfesor
import Table from "react-bootstrap/Table";

import React from "react";

import { useState, useEffect } from "react";

import axios from "axios"
import { Button, Dropdown, Spinner, Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";

import { AXIOS_HOST } from "../../../config/axios.config";
import { obtainLocalStorageItem } from "../../../utilities/localStorage.utility";
import EditClase from "./EditClase";



export default function VerClases() {

    const [clases, setClases] = useState(null)
    const [tipo, setTipo] = useState("todos")
    const [dropTipo, setDropTipo] = useState(null)
    const [activeSelec, setActiveSelect] = useState(null)

    const [editModal, setEditModal] = useState({show:false})

    const obtenerClases = async () => {
        await axios.get(`${AXIOS_HOST}/clases/all`).then((todas) => {
            setClases(todas.data.clases)
            setActiveSelect(todas.data.clases)
        })

    }

    const obtenerTipos = async () => {
        await axios.get(`${AXIOS_HOST}/clases/alltipos`).then((tipos) => {
            setDropTipo(tipos.data)
            //console.log("axios tipo", tipos.data)
        })
    }

    const handledropdown = (newtipo) => {
        console.warn("entre", newtipo)
        setTipo(newtipo)
    }

    const changeLoad = () => {
        if (tipo === "todos") setActiveSelect(clases)
        else {
            //console.log(tipo)
            //console.log(clases.filter((el) => el.tipo === tipo))
            setActiveSelect(clases.filter((el) => el.tipo === tipo))
        }
    }
    useEffect(() => {
        changeLoad()
    }, [tipo])

    useEffect(() => {
        //console.log("hola")
        obtenerClases()
        obtenerTipos()
    }, []);



    const axiosEdit = (clase) => {
        axios.put(`${AXIOS_HOST}/clases/editar`, clase, { headers: { token: obtainLocalStorageItem("token") } }).then(res => {
            //console.log(res.data)
            toast.success("Clase editada con exito")
            let tmpUsers = JSON.parse(JSON.stringify(clases))
            let index = tmpUsers.findIndex(tmp => tmp.id === clase.id)
            tmpUsers[index] = clase
            setActiveSelect(tmpUsers)
            setClases(tmpUsers)

            setEditModal({show:false})
        }).catch((err) => {
            //console.log(err)
            toast.error(err.response.data.message)
        })
    }

    const editarClase = (clase) => {
        //console.log(clase)
        setEditModal({
            show:true, 
            clase:clase,
            callback: (data) => {
                if(data.continua){
                    //console.log(data.clase)
                    axiosEdit(data.clase)

                    //changeLoad()
                }
                else{
                    setEditModal({show:false})
                    toast.warn("Edición cancelada")
                }
            }
        })
    }


    return (
        <div className="container ">
            <h2>Clases del complejo</h2>
            <Dropdown>
                <Dropdown.Toggle variant="success" >
                    Tipo de clase
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {dropTipo ? dropTipo.map((nom, index) => {
                        return (
                            <Dropdown.Item key={index} onClick={() => { handledropdown(nom.tipo) }}>{nom.tipo}</Dropdown.Item>
                        )
                    }) : <Dropdown.Item>nada</Dropdown.Item>}
                    <Dropdown.Item onClick={() => { handledropdown("todos") }}>Todos</Dropdown.Item>
                </Dropdown.Menu>

            </Dropdown>
            <div className="overflow-auto"  style={{maxHeight:"80vh"}}>
            <Table striped bordered >
                <thead>
                    <tr>
                        <th style={{ width: "200px" }}>Nombre</th>
                        <th style={{ width: "200px" }}>Descripción</th>
                        <th style={{ width: "200px" }}>Tipo</th>
                        <th style={{ width: "200px" }}>Acciones</th>
                    </tr>
                </thead>
                <tbody>

                    {activeSelec ? activeSelec.map((clase) => {

                        return (
                            <tr key={clase.id}>
                                <td>{clase.nombre}</td>
                                <td>{clase.descripcion}</td>
                                <td>{clase.tipo}</td>
                                <td><Button onClick={() => editarClase(clase)}>Editar</Button></td>
                            </tr>
                        )
                    }) : <Spinner></Spinner>}

                </tbody>



            </Table>
            </div>
                {editModal.show && <EditClase show={editModal.show} onHide={() => {setEditModal({show:false})}} callback={editModal.callback} clase={editModal.clase} tipos={dropTipo}/>}
                <ToastContainer/>
        </div>
    )
}
import {useEffect, useState} from 'react'
import { Table } from 'react-bootstrap'

import axios from 'axios'

import { AXIOS_HOST } from '../../../config/axios.config';

function VerAreas() {

  const [areas, setAreas] = useState([])

  const loadData = async () => {
    await axios.get(`${AXIOS_HOST}/areas/`).then(res => {
      console.log(res.data)
      setAreas(res.data)
    }).catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <div className='container'>
    <h1>Ver Areas</h1>

    <Table striped bordered hover style={{ textAlign: "left" }}>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Descripcion</th>
        
        </tr>
      </thead>
      <tbody>

        {areas.map((area) => (
          <tr key={area.idAreas}>
            <td>{area.nombre}</td>
            <td>{area.descripcion}</td>
          </tr>
        ))}
        
      </tbody>
    </Table>
    </div>
  )
}

export default VerAreas
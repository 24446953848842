import CrearProfesor from "./components/Profesores/crearProfesor";
import NavegadorPersonal from "./components/sidebar/navegadorConfig";
import ShowProfesores from "./components/Profesores/showProfesores";
import FormExample from "./components/testProf";

export default function Dev(){

    return(
        <div style={{height:"100vh"}}>
            
            <NavegadorPersonal/>

        </div>
    )
}
import axios from "axios"

import { useEffect, useState } from "react";
import { Button, Col, Dropdown, Row } from "react-bootstrap"
import Form from 'react-bootstrap/Form';
import ModalProfesores from "../modals/modalProfesores";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AXIOS_HOST } from "../../../config/axios.config";

export default function CrearProfesor() {



  const [modalShow, setModalShow] = useState(false);

  const [clases, setClases] = useState([])

  const [profesor, setProfesor] = useState({
    nombre: "",
    apellido: "",
    rut: "",
    descripcion: "",
    avatar: "",
    clases: [],
  })

  const [archivo, setArchivo] = useState("")
  const [preview, setPreview] = useState("")

  const loadClases = async () => {
    await axios.get(`${AXIOS_HOST}/clases/all`).then((res) => {
      //console.log(res.data.clases)
      setClases(res.data.clases)
    })

  }
  useEffect(() => {

    loadClases()
  }, [])

  useEffect(() => {
    if (archivo) {
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log("use", reader.result.includes("data:image"))
        setPreview(reader.result);
        setProfesor({
          ...profesor,
          avatar: reader.result
        })
      };
      reader.readAsDataURL(archivo);
    } else {
      setPreview(null)
    }
  }, [archivo]);


  function handleChange(e) {
    const value = e.target.value;
    console.log(profesor)
    setProfesor({
      ...profesor,
      [e.target.name]: value,
    });
  }

  const handleCheckbox = (e) => {
        
    if (e.target.checked) {
        setProfesor({
            ...profesor,
            [e.target.name]: [...profesor.clases, e.target.value]
        })
    } else {
        setProfesor({
            ...profesor,
            [e.target.name]: [...profesor.clases.filter((rol) => rol !== e.target.value)]
        })
    }
}

  const createProfesor = async (event) => {
    await axios.post(`${AXIOS_HOST}/profes/create`, profesor).then((res) => {
      console.log(profesor)
      toast("Profesor creado en BSD")
      event.target.reset()
      setValidated(false)
      
    }).catch((err) => {
      console.log(err)
      toast.error(err.response.data.message)
    })
    setModalShow({show:false})
  }

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault()
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      console.log(form.checkValidity())
      toast.warn("Debe ingresar todos los datos")
      setValidated(true);
    }
    else {
      let nuevo = preview
      //setProfesor({ ...profesor, avatar: nuevo })
      setModalShow({
        show: true,
        info: { ...profesor, avatar: nuevo },
        callback: (data) => {
          if (data.continua) {
            console.log(profesor)
            console.warn("CARGANDO DATOS A BSD")
            toast("Cargando datos a BSD")
            createProfesor(event)

          }
          else {
            toast.warn("Carga de datos cancelada")
          }
        }
      })

    }


  };


  return (
    <div className="container">
      <div>Creacion de profesor</div>

      <Form style={{ textAlign: "left" }} noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group md={4} as={Col} controlId="formGridRut">
            <Form.Label>Rut</Form.Label>
            <Form.Control required type="text" name="rut" placeholder="Ingrese Rut" onChange={handleChange} />
            <Form.Control.Feedback type="invalid">
              ingrese Rut
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group md={4} as={Col} controlId="formGridNombre">
            <Form.Label>Nombre</Form.Label>
            <Form.Control required type="text" name="nombre" placeholder="Ingresa Nombre" onChange={handleChange} />
          </Form.Group>

          <Form.Group md={4} as={Col} controlId="formGridApellido">
            <Form.Label>Apellido</Form.Label>
            <Form.Control required type="text" placeholder="Ingresa Apellido" name="apellido" onChange={handleChange} />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group md={4} as={Col} controlId="descForm">
            <Form.Label>Descripción</Form.Label>
            <Form.Control required as="textarea" rows={7} name="descripcion" onChange={handleChange} />
          </Form.Group>

          <Form.Group md={4} as={Col} controlId="exampleForm.ControlTextarea1">
            <Form.Label>Avatar</Form.Label>
            <Form.Control required type="file" accept="image/*" name="avatar" onChange={(e) => { setArchivo(e.target.files[0]) }} />
            {preview ? <div style={{ padding: "10px", }}><img src={preview} alt="preview" style={{ maxHeight: "140px", objectFit: "cover", aspectRatio: 1 / 1, border: "dotted", borderRadius: 100 }} /></div> : "Preview"}
          </Form.Group>



          <Form.Group md={4} as={Col} controlId="FormSelectClases">
            <Form.Label>Clases</Form.Label>
            <Dropdown autoClose={"outside"} >
              <Dropdown.Toggle  id="dropdown-basic" style={{width:"100%", backgroundColor:"white", border:"1px solid #ced4da", color:"#6c757d"}}>
                {profesor.clases.length > 0 ? "Clases Seleccionadas" : "Seleccione Clases"}
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ minWidth: "200px", paddingLeft:"5px" }}>
                {clases.map((clase) => (
                  <Form.Check
                    key={clase.id}
                    type="checkbox"
                    id={`checkbox-${clase.id}`}
                    label={clase.nombre}
                    name="clases"
                    checked={profesor.clases.includes(`${clase.id}`)}
                    value={clase.id}
                    onChange={handleCheckbox}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>




        </Row>


        <Button type="submit" variant="warning" >Enviar</Button>

      </Form>

      <ModalProfesores

        show={modalShow.show}
        funcion={modalShow}
        onHide={() => setModalShow(false)}
      />

      <ToastContainer />

    </div>

  )

}
import React from 'react'
import { Navigate, Route } from 'react-router-dom';


import Dev from '../../devViews/DevAccess';
import RoleGuard from '../../guards/authRoles';
import { PrivateRoutes } from '../../routes/routes';
import RoutesWithNotFound from '../../utilities/notFoundRoutes.utility';

import ShowProfesores from '../../devViews/components/Profesores/showProfesores';
import CrearProfesor from '../../devViews/components/Profesores/crearProfesor';
import AñadirClase from '../../devViews/components/Clases/añadirClase';
import VerClases from '../../devViews/components/Clases/verClases';
import VerHorarios from '../../devViews/components/Horas/verHorarios';
import CrearHora from '../../devViews/components/Horas/crearHora';
import RegistrarBitacora from '../../devViews/components/bitacora/registrarBitacora';
import VerBitacoras from '../../devViews/components/bitacora/verBitacoras';
import ShowUsers from '../../devViews/components/usuarios/showUsers';
import CreateUser from '../../devViews/components/usuarios/createUser';
import ShowNoticias from '../../devViews/components/Noticias/showNoticias';
import CrearNoticia from '../../devViews/components/Noticias/crearNoticia';
import Inicio from '../../devViews/components/Inicio/inicio';
import VerAreas from '../../devViews/components/areas/verAreas';
import CrearAreas from '../../devViews/components/areas/crearAreas';
import VerContactenos from '../../devViews/components/Contactenos/VerContactenos';
import ShowBeneficios from '../../devViews/components/beneficios/showBeneficios';
import CrearBeneficios from '../../devViews/components/beneficios/crearBeneficios';
import CrearBeneficiosTipos from '../../devViews/components/beneficios/crearBeneficiosTipos';
import ShowBeneficiosTipos from '../../devViews/components/beneficios/showBeneficiosTipos';
import CrearConvenio from '../../devViews/components/beneficios/crearConvenio';
import ShowConvenios from '../../devViews/components/beneficios/showConvenios';


export default function Private() {
  console.log("estoy en private")
  return (
    <RoutesWithNotFound>
      <Route path='/' element={<Navigate to={`./Private/inicio`} />} />
      <Route path='/Private' element={<Dev />} >
        <Route element={<RoleGuard rolesPermitidos={['"root"', '"Admin"', '"Gerente"']} />} >
          <Route path="inicio"  element={<Inicio/>} />

          <Route path='showareas' element={<VerAreas />} />
          <Route path='createarea' element={<CrearAreas />} />
          
          <Route path='showusers' element={<ShowUsers/>} />
          <Route path='createUser' element={<CreateUser />} />

          <Route path='showProfesores' element={<ShowProfesores />} />
          <Route path='crearprofesor' element={<CrearProfesor />} />

          <Route path='showclases' element={<VerClases />} />
          <Route path='añadirclase' element={<AñadirClase />} />

          <Route path='showhorarios' element={<VerHorarios />} />
          <Route path='crearhora' element={<CrearHora />} />

          <Route path='addbitacora' element={<RegistrarBitacora />} />
          <Route path='verbitacoras' element={<VerBitacoras />} />

          <Route path='shownoticias' element={<ShowNoticias/>} />
          <Route path='crearnoticia' element={<CrearNoticia />} />

          <Route path='vercontactenos' element={<VerContactenos />} />

          <Route path = 'showbeneficios' element={<ShowBeneficios/>} />
          <Route path = 'crearbeneficio' element={<CrearBeneficios/>} />
          <Route path = 'showtipobeneficio' element={<ShowBeneficiosTipos/>} />
          <Route path = 'crearconvenio' element={<CrearConvenio/>} />
          <Route path = 'showconvenios' element={<ShowConvenios/>} />
        </Route>

      </Route>
    </RoutesWithNotFound>
  )
}

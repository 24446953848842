import React from "react";
import { useState, useEffect } from "react";

import axios from "axios"

import { Form, Row, Col, Button, Dropdown, Spinner, Modal } from "react-bootstrap";
import ModalDinamico from "../modals/modalHorario";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HOST = process.env.REACT_APP_BCE_HOST || "http://localhost:3001"


export default function CrearHora() {

    const [modalShow, setModalShow] = React.useState(false);

    const [horario, setHorario] = useState({
        dia: "",
        hora: "",
        horaFin: "",
        clase: "",
        status: 0,
    })

    const [clases, setClases] = useState([])

    const obtenerClases = async () => {

        await axios.get(`${HOST}/clases/all`).then((todas) => {
            console.log(todas.data.clases)
            setClases(todas.data.clases)
        })
    }

    useEffect(() => {
        obtenerClases()
    }, [])

    function handleChange(e) {
        const value = e.target.value;
        console.log(value)
        setHorario({
            ...horario,
            [e.target.name]: value,
        });
    }

    function handleSwitch(e) {
        const value = e.target.checked;
        console.log(value)
        setHorario({
            ...horario,
            [e.target.name]: value,
        });
    }

    const cargarInfo = async () => {
        await axios.post(`${HOST}/horarios/create`, horario).then((respuesta) => {
            console.log(respuesta)
            toast(respuesta.data.mensaje);
            setModalShow(false)
        })
    }

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {

        event.preventDefault()
        const form = event.currentTarget;
        
        if (form.checkValidity() === false) {
            
            event.preventDefault();
            event.stopPropagation();
            console.log(form.checkValidity())
            toast("Datos incorrectos")
            setValidated(true);
            
        }
        else {
            console.log("debug de tmp", clases.findIndex(tmp => tmp.id === parseInt(horario.clase)) )
            
            setModalShow({
                show: true,
                info: { Día: horario.dia, Hora: horario.hora, Clase: clases[clases.findIndex(tmp => tmp.id === parseInt(horario.clase))].nombre, Estado: horario.status ? "Habilitada" : "Deshabilitada" },
                callback: (data) => {
                    if (data.continua) {
                        console.log(horario)
                        console.warn("CARGANDO DATOS A BSD")
                        cargarInfo()
                    }
                    else {
                        toast("Carga de datos cancelada")
                    }
                }
            })

        }

    };


    return (
        <div className="container">
            <Form noValidate validated={validated} onSubmit={(e) => { handleSubmit(e) }}>

                <Form.Group as={Row} className="mb-3" controlId="formDia">
                    <Form.Label column sm={2}>Día</Form.Label>
                    <Col sm={2}>
                        <Form.Select required name="dia" onChange={(e) => handleChange(e)}>
                            <option value="">Seleccione Día</option>
                            <option value="Lunes">Lunes</option>
                            <option value="Martes">Martes</option>
                            <option value="Miercoles">Miercoles</option>
                            <option value="Jueves">Jueves</option>
                            <option value="Viernes">Viernes</option>
                            <option value="Sabado">Sabado</option>

                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            ingrese Dia
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formHora">
                    <Form.Label column sm={2}>Hora inicio</Form.Label>
                    <Col sm={2}>
                        <Form.Control required type="time" name="hora" placeholder="Ingrese hora" onChange={(e) => handleChange(e)} />
                        <Form.Control.Feedback type="invalid">
                            ingrese hora
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formHora">
                    <Form.Label column sm={2}>Hora fin</Form.Label>
                    <Col sm={2}>
                        <Form.Control required type="time" name="horaFin" placeholder="Ingrese hora" onChange={(e) => handleChange(e)} />
                        <Form.Control.Feedback type="invalid">
                            ingrese hora
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formClase">
                    <Form.Label column sm={2}>clase</Form.Label>
                    <Col sm={2}>
                        <Form.Select required name="clase" onChange={(e) => handleChange(e)}>
                            <option value="">Seleccione Clase</option>
                            {clases ? clases.map((clase) => {
                                return (
                                    <option key={clase.nombre} value={clase.id}>{clase.nombre}</option>
                                )
                            }) : <option value={null}>Cargando...</option>}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            Ingrese Clase
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formActivo">
                    <Form.Label column sm={2}>¿Hora Activa?</Form.Label>
                    <Col sm={2}>
                        <Form.Check

                            type="switch"
                            id="custom-switch"
                            name="status"
                            label="Ingreso estado inicial al sistema"
                            defaultChecked={horario.status}
                            onChange={(e) => handleSwitch(e)}
                        />
                    </Col>
                </Form.Group>

                <Col sm={4} style={{ marginTop: "3rem" }}>
                    <Button type="submit">Enviar</Button>
                </Col>
            </Form>



            <ModalDinamico
                datos={horario}
                show={modalShow.show}
                funcion={modalShow}
                onHide={() => setModalShow(false)} />

            <ToastContainer />
        </div>
    )
}
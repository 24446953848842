import { useState, useEffect } from "react";
import axios from "axios";
import { AXIOS_HOST } from "../config/axios.config";

import FooterHome from "../Components/footerHome";
import TestHeader from "../Components/Header/headerV2";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import test from "../test/beneficios.json"
import FooterV2 from "../Components/footerV2";
import CardBeneficios from "../Components/beneficios/cardBeneficios";

export default function Beneficios() {

    const [modalShow, setModalShow] = useState(false);
    const [modalData, setModalData] = useState({
        "nombre": "",
        "desc": "",
        "codigo": ""
    })

    const [beneficios, setBeneficios] = useState([])
    const [baseBeneficios, setBaseBeneficios] = useState([])

    const [tiposBeneficios, setTiposBeneficios] = useState([])


    const handleBeneficios = ({ nombre, descripcion, codigo, imagen }) => {
        setModalShow(true)
        setModalData({
            nombre: nombre,
            codigo: codigo,
            descripcion: descripcion,
            image: `${AXIOS_HOST}/filesbeneficios/${imagen}`,
            callback: (data) => {
                setModalShow(false)
            }
        })
    }

    useEffect(() => {
        document.title = "Parque Austral - Beneficios"
        axios.get(`${AXIOS_HOST}/beneficios/activos`).then(res => {
            console.log(res.data)
            setBeneficios(res.data.beneficios)
            setBaseBeneficios(res.data.beneficios)
        }).catch(err => console.log(err))

        axios.get(`${AXIOS_HOST}/beneficios/tipos`).then(res => {
            console.log(res.data.tiposBeneficios)
            setTiposBeneficios(res.data.tiposBeneficios)
        }
        ).catch(err => console.log(err))
    }, [])


    return (
        <div className="MyBackground">
            <TestHeader />
            <div className="container" style={{ backgroundColor: "white", minHeight: "71.5vh", paddingTop: "3vh", paddingBottom: "3vh" }}>
                { tiposBeneficios.length > 0 &&
                <Tabs
                    defaultActiveKey={`${tiposBeneficios[0].id_tipoBeneficio}`}
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    {tiposBeneficios.map((tip, index) => {
                        return (
                            <Tab key={tip.nombre + index} eventKey={tip.id_tipoBeneficio} title={tip.nombre} style={{ textAlign: "center" }}>
                                <Row>
                                    {beneficios.filter(ben => ben.id_tipoBeneficio === tip.id_tipoBeneficio).map((ben, index) => {
                                        return (

                                            <CardBeneficios key={index + ben.nombre} beneficio={ben} handleBeneficios={handleBeneficios} />

                                        )
                                    })}
                                </Row>
                            </Tab>
                        )
                    })}
                </Tabs>
                }
            </div>

             {/**
            <div className="container" style={{ backgroundColor: "white", minHeight: "71.5vh", paddingTop: "3vh", paddingBottom: "3vh" }}>
                <Tabs
                    defaultActiveKey={`${test.tipo[0]}`}
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    {test.tipo.map((tip, index) => {
                        return (
                            <Tab key={tip + index} eventKey={tip} title={tip} style={{ textAlign: "center" }}>
                                <Row >
                                    {test[`${tip}`].map((ben, index) => {
                                        return (

                                            <CardBeneficios beneficio={ben} handleBeneficios={handleBeneficios} />

                                        )
                                    })}
                                </Row>
                            </Tab>
                        )
                    })}
                </Tabs>
            </div>
              */}




            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}

                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12} md={6} lg={6} xl={6}>
                            <img crossOrigin="anonymous" alt="imagen beneficio" src={modalData.image} style={{ width: "100%" }} />
                        </Col>
                        <Col xs={12} md={6} lg={6} xl={6}>
                            <h4>{modalData.nombre}</h4>
                            <p style={{ textAlign: "left", overflowWrap: 'break-word', whiteSpace: "pre-wrap" }}>
                                {modalData.descripcion}
                            </p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {modalData.codigo}
                </Modal.Footer>
            </Modal>
        </div>
    )
}
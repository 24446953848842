import axios from "axios"
import React from "react"
import { useState, useEffect } from "react"
import { Button, Spinner, Table, Dropdown, Row } from "react-bootstrap"
import { ToastContainer, toast } from "react-toastify"

import { AXIOS_HOST } from "../../../config/axios.config"
import EditHora from "./editHora"
import { obtainLocalStorageItem } from "../../../utilities/localStorage.utility"
import DeleteHora from "./deleteHora"



export default function VerHorarios() {

    const [horario, setHorario] = useState([])
    const [horarioActual, setHorarioActual] = useState([])

    const [clase , setClase] = useState("todos")
    const [dropClase, setDropClase] = useState(null)

    const [showEdit, setShowEdit] = useState({show:false, onHide:null, hora:null, callback:null})
    const [showDelete, setShowDelete] = useState({show:false, onHide:null, hora:null, callback:null})


    const obtenerHorarios = async () => {
        await axios.get(`${AXIOS_HOST}/horarios/all`).then( (horas) =>{
            //console.log("axios horarios:", horas.data.horarios)
            setHorario(horas.data.horarios)
            setHorarioActual(horas.data.horarios)
        } )
    }

    const obtenerClases = async () => {
        await axios.get(`${AXIOS_HOST}/clases/all`).then( (todas) => {
            //console.log("Axios clases",todas.data.clases)
            setDropClase(todas.data.clases)
        })
    }

    const handleHorarios = () =>{
        //console.log("cambio de horario")
        if (clase === "todos") setHorarioActual(horario)
        else{
            //console.log(clase)
            //console.log(horario.filter((el) => el.clase === clase))
            setHorarioActual(horario.filter((el) => el.clase === clase))
        }
    }

    const handledropdown = (newClase) => {
        //console.warn("entre", newClase)
        setClase(newClase)
    }

    useEffect(() => {
        handleHorarios()
    }, [clase])

    useEffect(() => {
        obtenerHorarios()
        obtenerClases()
    },[])

    const editHora = async (hora) => {
        console.log(hora)


        await axios.put(`${AXIOS_HOST}/horarios/edit`, hora).then( (res) => {
            console.log("envie")
            toast.success("Horario editado con exito")
            let tmphorario = JSON.parse(JSON.stringify(horario))
            let tmphorarioActual = JSON.parse(JSON.stringify(horarioActual))
            let index = tmphorario.findIndex(tmp => tmp.idhorarios === hora.idhorarios)
            let indexActual = tmphorarioActual.findIndex(tmp => tmp.idhorarios === hora.idhorarios)
            tmphorario[index] = hora
            tmphorarioActual[indexActual] = hora
            setHorarioActual(tmphorarioActual)
            setHorario(tmphorario)

            setShowEdit({show:false, onHide:null, hora:null, callback:null})
        }).catch( (err) => {
            console.log(err)
            toast.error("Error al editar el horario")
        })
    }

    const handleEdit = (hora) => {
        console.log("entra",hora)
        setShowEdit({
            show:true,
            onHide: () => setShowEdit({show:false, onHide:null, hora:null, callback:null}),
            hora: hora,
            callback: (data) => {
                if (data.continua) {
                    //console.log("continua")
                    editHora(data.edited)
                    //obtenerHorarios()
                    //toast.success("Horario editado con exito")
                } else {
                    //console.log("no continua")
                    toast.warn("Se cancelo la edición del horario")
                    setShowEdit({show:false, onHide:null, hora:null, callback:null})
                }
            }

        })
    }

    const handleDelete = (hora) => {
        setShowDelete({
            show:true,
            onHide: () => setShowDelete({show:false, onHide:null, hora:null, callback:null}),
            hora: hora,
            callback: async (data) => {
                console.log(data)
                if (data.continua) {
                    
                    await axios.delete(`${AXIOS_HOST}/horarios/delete`, {data: {id: data.hora.idhorarios}, headers:{token:obtainLocalStorageItem("token")}}).then( (res) => {
                        console.log("envie")
                        let tmphorario = JSON.parse(JSON.stringify(horario))
                        let tmphorarioActual = JSON.parse(JSON.stringify(horarioActual))
                        let index = tmphorario.findIndex(tmp => tmp.idhorarios === data.hora.idhorarios)
                        let indexActual = tmphorarioActual.findIndex(tmp => tmp.idhorarios === data.hora.idhorarios)
                        tmphorario.splice(index,1)
                        tmphorarioActual.splice(indexActual,1)
                        setHorarioActual(tmphorarioActual)
                        setHorario(tmphorario)
                        setShowDelete({show:false, onHide:null, hora:null, callback:null})
                        toast.success("Horario eliminado con exito")

                    }).catch( (err) => {
                        console.log(err)
                        toast.error("Error al eliminar el horario")
                    })

                } else {
                    //console.log("no continua")
                    toast.warn("Se cancelo la eliminación del horario")
                    setShowDelete({show:false, onHide:null, hora:null, callback:null})
                }
            }
        })
    }

    
    return(
        <div className="container">
            <h1>Horarios del sistema</h1>

            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {clase ? clase : "Seleccione"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handledropdown("todos")}>Todos</Dropdown.Item>
                    {
                        dropClase ? dropClase.map( (clases) => {
                            return(
                                <Dropdown.Item onClick={() => handledropdown(clases.nombre)}>{clases.nombre}</Dropdown.Item>
                            )
                        }) : "Cargando..."

                    }
                </Dropdown.Menu>
            </Dropdown>

            <div className="overflow-auto" style={{maxHeight:"80vh"}}>
            <Table  striped bordered size="sm">
                <thead>
                    <tr>
                        <td style={{width:40}}>id</td>
                        <td width={150}>Hora</td>
                        <td width={150}>Clase</td>
                        <td width={150}>Acciones</td>
                    </tr>
                </thead>
                <tbody>
                    { horarioActual.length !== 0 ? horarioActual.map( (horas) => {
                        //console.log(horas)
                        return(
                            <tr key={horas.idhorarios}>
                                <td>{horas.idhorarios}</td>
                                <td>{horas.dia} {horas.hora}</td>
                                <td>{horas.clase}</td>
                                <td>
                                    
                                        <Button  onClick={() => handleEdit(horas)}>Editar</Button>
                                        <Button variant="danger" onClick={() => handleDelete(horas)}>Eliminar</Button>
                                    
                                </td>
                            </tr>
                        )
                    }) : <td colSpan={4}><Spinner/></td> }

                </tbody>
            </Table>
            </div>

    {showEdit.show ?
            <EditHora 
                show={showEdit.show} 
                onHide={() => showEdit.callback({continua: false})} 
                horario = {showEdit.hora} 
                clases={dropClase} 
                callback = {showEdit.callback}/> : null
            }

    {showDelete.show ?
            <DeleteHora
                show={showDelete.show}
                onHide={() => showDelete.callback({continua: false})}
                horario = {showDelete.hora}
                callback = {showDelete.callback}/> : null
            }
            
            <ToastContainer/>
        </div>

    )
}
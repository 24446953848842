
import MisionVision from "./landing2/mision-vision";

export default function landing2(){

    return(
        <div style={{
            backgroundColor:"#000000",
            paddingBottom:"3%",

        }}>
            
            
            <div className="d-none d-sm-block" style={{ paddingLeft:"2%", paddingTop:"2%"}}>
                
                <h1 className="SoloTitulos" style={{lineHeight:1 ,color:"white", textAlign:"left", fontSize:"calc(1em + 10vw)",textTransform:"uppercase"}}># vive la<br /></h1>
                <h1 className="SoloTitulos"    style={{lineHeight:1 , textAlign:"left", fontSize:"calc(1em + 10vw)" ,color:"#46c3c7", textTransform:"uppercase"}}> experiencia/</h1> 
                
            </div>
            <div className="d-block d-sm-none" style={{ paddingLeft:"2%", paddingTop:"6%"}}>
                
                <h1 className="SoloTitulos" style={{lineHeight:1 , textAlign:"left", fontSize:"calc(10vw + 3vh )", textTransform:"uppercase", color:"white"}}># vive la</h1>
                <h1 className="SoloTitulos" style={{lineHeight:"$xs" , textAlign:"left", fontSize:"calc(8vw + 3vh )",textTransform:"uppercase", color:"#46c3c7"}}> experiencia/</h1> 
                
            </div>
            

            <MisionVision/>
            
            
        </div>
    )
}
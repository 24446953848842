import { Modal, Button, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "axios";

import { AXIOS_HOST } from "../../../config/axios.config";

export default function EditClase({clase, show, onHide, tipos,callback}) {

    //const [tipos, setTipos] = useState(null)
    //console.log(clase)
    const [editClase, setEditClase] = useState({
            id: clase.id,
            nombre: clase.nombre,
            descripcion: clase.descripcion,
            tipo: clase.tipo,
            idTipo: clase.idTipo
        })

/*
    const obtenerTipos = async () => {
        await axios.get(`${AXIOS_HOST}/clases/alltipos`).then((tipos) => {
            setTipos(tipos.data)
            console.log("axios tipo", tipos.data)
        })
    }
    useEffect(() => {
        obtenerTipos()
    }, [])
*/
    const handleChange = (e) => {
        //setEditClase({...editClase, [e.target.name]: e.target.value})
        if(e.target.name === "idTipo") {
            let tipo = tipos.find((el) => el.id === parseInt(e.target.value))
            setEditClase({...editClase, tipo: tipo.tipo, idTipo: tipo.id})
        }
        else {
            setEditClase({...editClase, [e.target.name]: e.target.value})
        }
        console.log(editClase)
    }

    
    return(
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Editar Clase</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control type="text" name="nombre" defaultValue={clase.nombre} onChange={handleChange}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Descripcion</Form.Label>
                        <Form.Control as="textarea" rows={2} name="descripcion" defaultValue={clase.descripcion} onChange={handleChange}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Tipo</Form.Label>
                        <Form.Select name="idTipo" defaultValue={clase.idTipo} onChange={handleChange}>
                            {tipos && tipos.map((el) => {
                                return(
                                    <option key={el.id} value={el.id}>{el.tipo}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {callback({continua:false})}}>Cancelar</Button>
                <Button variant="primary" onClick={() => {callback({continua:true, clase:editClase})}}>Editar</Button>
            </Modal.Footer>
        </Modal>


    )



}
// Description: Componente para crear usuarios con un formulario de rut, nombre, apellido, email, contraseña, avatar y rol. El rol es un select que se llena con los roles que se encuentran en la base de datos. El avatar es un input de tipo file que permite subir una imagen. El formulario tiene validaciones de campos requeridos y de formato de rut. Al enviar el formulario se muestra un modal de confirmación con los datos ingresados. Si se confirma se envía la información al backend y se muestra un mensaje de éxito o error según corresponda. Si se cancela se muestra un mensaje de cancelación. El componente se encuentra en la ruta cliente\src\devViews\components\usuarios\createUser.jsx

import React, { useState, useEffect } from 'react'
import { Form, Col, Row, Button, Modal, Dropdown, Badge, Image } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { AXIOS_HOST } from '../../../config/axios.config';
import { obtainLocalStorageItem } from '../../../utilities/localStorage.utility';


function CreateUser() {

    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);

    const [imageUrl, setImageUrl] = useState(null);

    const handleClose = () => {
        setShow(false);
        toast.warn("Se a cancelado la creación de usuario")

    }
    const handleShow = () => setShow(true);

    const [user, setUser] = useState({
        rut: "",
        nombre: "",
        apellido: "",
        email: "",
        password: "",
        status: 1,
        avatar: [],
        roles: [],
        token: obtainLocalStorageItem("token")
    })

    const [avatarUpload, setAvatarUpload] = useState([])

    const [roles, setRoles] = useState([])

    useEffect(() => {
        const getRoles = async () => {
            const res = await axios.get(`${AXIOS_HOST}/roles/allroles`)
            console.log(res.data.roles)
            setRoles(res.data.roles.map((rol) => rol.nombre))
        }
        getRoles()
        console.log(roles)

    }, [])

    const handleFileInput = (e) => {

        setUser({
            ...user,
            avatar: e.target.files[0]
        })
        setImageUrl(URL.createObjectURL(e.target.files[0]));

    }

    const handleChange = (e) => {
        const value = e.target.value;
        setUser({
            ...user,
            [e.target.name]: value,
        });
    }

    function formatRut(event) {
        const rutInput = event.target;
        let rut = rutInput.value.replace(/[^\dkK]/g, '');
        const dv = rut.slice(-1)
        rut = rut.slice(0,-1)
        
        if (rut.length > 6) {
            rut = `${rut.slice(0, -6)}.${rut.slice(-6, -3)}.${rut.slice(-3)}`;
          } else if (rut.length > 3) {
            rut = `${rut.slice(0, -3)}.${rut.slice(-3)}`;
          }
          rutInput.value = `${rut}-${dv}`;
      }

    const handleCheckbox = (e) => {

        if (e.target.checked) {
            setUser({
                ...user,
                [e.target.name]: [...user.roles, e.target.value]
            })
        } else {
            setUser({
                ...user,
                [e.target.name]: [...user.roles.filter((rol) => rol !== e.target.value)]
            })
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            toast.error("Debe completar todos los campos")
        }
        else {
            handleShow()

        }
        setValidated(true);
    };

    const createUser = async () => {

        const formData = new FormData()
        formData.append("rut", user.rut)
        formData.append("nombre", user.nombre)
        formData.append("apellido", user.apellido)
        formData.append("email", user.email)
        formData.append("password", user.password)
        formData.append("status", user.status)
        formData.append("roles", user.roles)
        formData.append("token", user.token)


        formData.append("file", user.avatar)

        const res = await axios.post(`${AXIOS_HOST}/users/create`, formData, { headers: { token: obtainLocalStorageItem("token"), 'Content-Type': 'multipart/form-data' }, })
            .then((res) => {
                console.log(res.data)
                setShow(false)
                toast.success(res.data.message)
            })
            .catch((err) => {

                toast.error(res.data.message)
            })

    }

    return (
        <div className="container">
            <h2>Creación de usuario</h2>
            <Form style={{ textAlign: "left" }} noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Form.Group md={4} as={Col} controlId="formGridRut">
                        <Form.Label>Rut</Form.Label>
                        <Form.Control required type="text" name="rut" placeholder="Ingrese Rut" onInput={formatRut} onChange={handleChange} />
                        <Form.Control.Feedback type="invalid">
                            ingrese Rut
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group md={4} as={Col} controlId="formGridNombre">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control required type="text" name="nombre" placeholder="Ingresa Nombre" onChange={handleChange} />
                        <Form.Control.Feedback type="invalid">
                            Ingrese Nombre
                        </Form.Control.Feedback>

                    </Form.Group>
                    <Form.Group md={4} as={Col} controlId="formGridApellido">
                        <Form.Label>Apellido</Form.Label>
                        <Form.Control required type="text" placeholder="Ingresa Apellido" name="apellido" onChange={handleChange} />
                        <Form.Control.Feedback type="invalid">
                            Ingrese Apellido
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group md={4} as={Col} controlId="formGridEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control required type="email" placeholder="Ingresa Email" name="email" onChange={handleChange} />
                        <Form.Control.Feedback type="invalid">
                            Ingrese Email
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group md={4} as={Col} controlId="formGridPassword">
                        <Form.Label>Contraseña</Form.Label>
                        <Form.Control required type="password" placeholder="Ingresa Contraseña" name="password" onChange={handleChange} />
                    </Form.Group>
                    <Form.Group md={4} as={Col} controlId="formGridAvatar">
                        <Form.Label>Avatar</Form.Label>
                        <Form.Control required type="file" placeholder="Ingrese Avatar" name="avatar" onChange={handleFileInput} />
                        <Form.Control.Feedback type="invalid">
                            Ingrese Avatar
                        </Form.Control.Feedback>

                        {imageUrl && (<>
                            <div>Image Preview:</div>
                            <Image roundedCircle src={imageUrl} alt="preview" height="120px" />
                        </>
                        )}

                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group md={4} as={Col} controlId="formGridRol">
                        <Form.Label>Roles: </Form.Label>

                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                {user.roles.length > 0 ? "Roles seleccionados" : "Selecciona Roles"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ textAlign: "center" }} >

                                {roles.map((rol) => (
                                    <div className='container' key={rol} style={{ display: 'flex', alignItems: 'center' }}>
                                        <Form.Check
                                            type="checkbox"
                                            name='roles'
                                            id={`${rol}`}

                                            value={`${rol}`}
                                            checked={user.roles.includes(`${rol}`)}
                                            onChange={handleCheckbox} />
                                        <label htmlFor={`${rol}`} style={{ flexGrow: 1 }}>{rol}</label>
                                    </div>
                                ))}
                            </Dropdown.Menu>

                        </Dropdown>
                        {user.roles.length > 0 &&
                            <div style={{ marginTop: '10px' }}>
                                {user.roles.map((rol) => (
                                    <Badge pill variant="info" className="mx-1 my-1" key={rol}>
                                        {rol}
                                    </Badge>
                                ))}
                            </div>
                        }
                    </Form.Group>
                </Row>
                <Button variant="primary" type="submit">
                    Crear Usuario
                </Button>
            </Form>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>¿Está seguro que desea crear el usuario?</p>
                    <p>Rut: {user.rut}</p>
                    <p>Nombre: {user.nombre}</p>
                    <p>Apellido: {user.apellido}</p>
                    <p>Email: {user.email}</p>
                    <p>Contraseña: {user.password}</p>
                    {/*<p>Avatar: {user.avatar}</p>*/}
                    <p>Rol: {user.roles.length > 0 &&
                        <>
                            {user.roles.map((rol) => (
                                <Badge pill variant="info" className="mx-1 my-1" key={rol}>
                                    {rol}
                                </Badge>
                            ))}
                        </>
                    }
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={() => createUser()}>
                        Crear
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </div>



    )
}

export default CreateUser
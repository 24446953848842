import React from "react";

import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";


export default function ModalDinamico(props){
    
        return (
          <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
              Confirmacion de información
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4>¿Desea enviar esta información?</h4>

              <ul>
              {props.funcion.info ? Object.keys(props.funcion.info).map((key) =>{
                return(
                  <li key={key}> {key} : {props.funcion.info[key]}</li>
                )
              }):""}
              </ul>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={()=>{props.onHide(); props.funcion.callback({continua:false})}}>Close</Button>
              <Button onClick={() => props.funcion.callback({continua:true})}>Aceptar</Button>
            </Modal.Footer>
          </Modal>
        );
      
    
}
import { useState, useEffect } from 'react'
import axios from 'axios'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Collapse from 'react-bootstrap/Collapse';

import { obtainLocalStorageItem } from '../../../utilities/localStorage.utility'
import { Col, Form, Row } from 'react-bootstrap';
import EditarBitacora from './editarBitacora';
import VerEspecifica from './verEspecifica';
import MyPaginacion from '../../../Components/Pagination/Pagination';
import { AXIOS_HOST } from '../../../config/axios.config';


export default function VerBitacoras() {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const lastIndex = currentPage * rowsPerPage
  const firstIndex = lastIndex - rowsPerPage

  const [bitacoras, setBitacoras] = useState([])
  const [bitacorasBase, setBitacorasBase] = useState([])
  const [areas, setAreas] = useState([])

  const [modalShow, setModalShow] = useState({ show: false })

  const [editIndex, setEditIndex] = useState(null)

  const handleEditIndex = (id) => {
    if (editIndex === id) {
      setEditIndex(null)
    } else {
      setEditIndex(id)
    }
  }

  const loadBitacoras = async () => {
    await axios.get(`${AXIOS_HOST}/bitacora/`).then((res) => {
      console.log(res.data)
      setBitacoras(res.data)
      setBitacorasBase(res.data)
    })
  }

  const loadAreas = async () => {
    await axios.get(`${AXIOS_HOST}/areas/`).then((res) => {
      console.log(res.data)
      setAreas(res.data)
    })
  }

  useEffect(() => {
    loadBitacoras()
    loadAreas()
  }, [])

  const handleUpdatesOfBitacoras = (elemento) => {
    setBitacoras(elemento)
    setBitacorasBase(elemento)
  }

  return (
    <div className='container'>
      <h1>Ver Bitacoras</h1>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Area</th>
            <th>Descripción</th>
            <th>RUT Creador</th>
            <th>Fecha Creación</th>
            <th>Fecha Update</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {bitacoras.map((bitacora) =>{ 
            
            const fechaISOCreated = new Date(bitacora.createdAt)
            const horaCreated = fechaISOCreated.toLocaleDateString('es-CL', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false });

            const fechaISOUpdated = new Date(bitacora.updatedAt)
            const horaUpdated = fechaISOUpdated.toLocaleDateString('es-CL', {  year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false });

            return (
              <>
            <tr key={bitacora.idBitacora}>
              <td>{bitacora.nombre}</td>
              <td style={{overflow:'hidden', textOverflow: 'ellipsis', whiteSpace:'nowrap', maxWidth:"200px"}}>{bitacora.descripcion}</td>
              <td>{bitacora.idUsuario}</td>
              <td>{horaCreated}</td>
              <td>{bitacora.updatedAt ? horaUpdated : "Sin actualización"}</td>
              <td>
                <Row style={{textAlign:'center'}}>
                  <Col style={{width:"66.55px"}}>

                  <Button variant='warning'
                  style={{width:"66.55px"}} disabled={bitacora.idUsuario !== obtainLocalStorageItem("rut")} onClick={() => handleEditIndex(bitacora.idBitacora)}> Editar </Button> 
                  </Col>
                  <Col style={{width:"66.55px"}}>
                  <Button
                  style={{width:"66.55px"}} onClick={() => setModalShow({ show: true, bitacora: bitacora, createdAt:horaCreated, updatedAt:horaUpdated })}> Ver </Button>
                  </Col>
                </Row>
              </td>
            </tr>

            <Collapse key={"update" + bitacora.idBitacora} in={editIndex === bitacora.idBitacora}>
            <td colSpan={6}>
              <EditarBitacora
                bitacora={bitacora}
                areas={areas}
                handleUpdatedBitacoras={handleUpdatesOfBitacoras}
                bitacoras={bitacorasBase}

              />
            </td>
            </Collapse>
            </>
          )}).slice(firstIndex, lastIndex)}
        </tbody>
      </Table>
      <MyPaginacion  
              rowsPerPage = {rowsPerPage} 
              totalElements = {bitacoras.length}
              currentPage  = {currentPage}
              setCurrentPage = {setCurrentPage}
      />

              <VerEspecifica 
                show={modalShow.show}
                onHide={() => setModalShow({ show: false })}
                bitacora={modalShow.bitacora}
                createdAt={modalShow.createdAt}
                updatedAt={modalShow.updatedAt}
                />
    </div>
  )
}


import React from "react";


import Landing1 from "../Components/landing1"
import Landing2 from "../Components/landing2"
import Landing3 from "../Components/landing3";
import Landing5Test from "../Components/landing5/landing5Test";
import Landing4 from "../Components/landing4/landing4";
export default function Home() {

    return(
        <div style={{overflow:"hidden"}}>
            
            <Landing1/>
            <Landing2/>
            {/*<Landing3/>*/}
            <Landing4/>
            <Landing5Test/>
            
        </div>
    )
}
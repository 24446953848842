import { useState } from "react";

import { Modal, Form, Button, Col, Row } from "react-bootstrap";

export default function DeleteHora({ show, onHide, horario, callback }) {

    return(
        <Modal
            show={show}
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title>Eliminar Horario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Seguro que desea eliminar el horario?</p>
                <p>{horario.dia}{horario.hora}</p>
                <p>{horario.clase}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => callback({continua:false})}>
                    Cancelar
                </Button>
                <Button variant="danger" onClick={() => callback({continua:true, hora:horario})}>
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
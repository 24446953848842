import { Modal, Button } from "react-bootstrap"


function VerMensaje({show, onHide, contact, createdAt}) {
    return (
        <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Mensaje del dia {createdAt}
                </Modal.Title>
            </Modal.Header>
            {contact ?
            <Modal.Body style={{ overflowWrap: 'break-word', whiteSpace: "pre-wrap", textAlign: "left", }}>
                 
                <h5>Nombre Cliente:</h5>
                <p>{contact.nombre} </p>

                <h5>E-mail:</h5>
                <p>{contact.email}</p>

                <h5>Telefono:</h5>
                <p>{contact.telefono}</p>
    
                <h5>Mensaje:</h5>
                <p style={{ maxWidth:"600px"}}>{contact.mensaje}</p>
     
    
            </Modal.Body>
            : null}
            <Modal.Footer>
                <Button onClick={onHide}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    
      )
    }

export default VerMensaje
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Contactenos from './contactenosV2';

export default function Landing5Test() {
  return (
    
    <div style={{backgroundColor:"#242424"}}>
      <Row>
        <Col  sm={6}>
          <iframe className='d-none d-sm-block' title="location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2792.2549606631906!2d-72.07836394837321!3d-45.585419124735694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xbdf2c4e4d64bf4ed%3A0x279baabcbcf5ba14!2sComplejo%20Deportivo%20Parque%20Austral!5e0!3m2!1ses-419!2scl!4v1674243300815!5m2!1ses-419!2scl" width="100%" height="100%" style={{border:"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          <iframe className='d-block d-sm-none' title="location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2792.2549606631906!2d-72.07836394837321!3d-45.585419124735694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xbdf2c4e4d64bf4ed%3A0x279baabcbcf5ba14!2sComplejo%20Deportivo%20Parque%20Austral!5e0!3m2!1ses-419!2scl!4v1674243300815!5m2!1ses-419!2scl" width="100%" height="400px" style={{border:"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

        </Col>
        <Col sm={6}><Contactenos/></Col>
      </Row>
    </div>
    
  );
}


//se desea utilizaraxios para enviarlos datos de un evento ocurrido en lo cual se incluyen el idArea, descripcion y idUsuario.


import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Modal from 'react-bootstrap/Modal'

import 'react-toastify/dist/ReactToastify.css';

import { AXIOS_HOST } from '../../../config/axios.config';
import { obtainLocalStorageItem } from '../../../utilities/localStorage.utility';




function RegistrarBitacora() {

  const [modalShow, setModalShow] = useState({ show: false })

  const [bitacora, setBitacora] = useState({
    idArea: null,
    descripcion: "",
    idUsuario: obtainLocalStorageItem("rut")
  })

  const [areas, setAreas] = useState([])

  const loadAreas = async () => {
    await axios.get(`${AXIOS_HOST}/areas/`).then((res) => {
      console.log(res.data)
      setAreas(res.data)
    })
  }

  useEffect(() => {
    loadAreas()
  }, [])



  function handleChange(e) {
    const value = e.target.value;
    setBitacora({
      ...bitacora,

      [e.target.name]: value,
    });

  }

  const createBitacora = async (e) => {
    await axios.post(`${AXIOS_HOST}/bitacora/create`, bitacora).then((res) => {
      console.log(bitacora)
      e.target.idArea.value = null
      e.target.descripcion.value = ""
      e.target.reset()
      toast.success("Bitacora creada en BSD")
    }).catch((err) => {
      console.log(err)
      toast.error("Error al crear bitacora")
    })
  }

  const [validated, setValidated] = useState(false);

  const handleSubmit = (e) => {
    console.log(bitacora)
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);

    } else {
      setModalShow({
        show: true,
        info: bitacora,
        callback: ((data) => {
          if(data.continua){
          createBitacora(e)
          setModalShow({ show: false })
        }
        else{
          toast.warn("Creación de evento cancelada")
          setModalShow({ show: false })
        }



      })
    })

  }
};

  return (
    <>
      <div className="container">
        <h1>Registrar Evento</h1>

        <Form noValidate validated={validated} onSubmit={(e) => handleSubmit(e)}>
          <Row>
            <Form.Group className="mb-3" as={Col} md={6} controlId="Descripcion">
              <Form.Label>Descripcion</Form.Label>
              <Form.Control as={"textarea"} placeholder="Descripcion" name="descripcion" onChange={handleChange} required />
            </Form.Group>

            <Form.Group className="mb-3" as={Col} md={6} controlId="idArea">
              <Form.Label>Area</Form.Label>
              <Form.Select  name="idArea" onChange={handleChange} required>
                <option value="">Seleccione una opcion</option>
                {areas.map((area) => {
                  
                  return <option key={area.idAreas} value={area.idAreas}>{area.nombre}</option>
                })}

              </Form.Select>
            </Form.Group>
          </Row>
          <Button variant="primary" type="submit" > Registrar </Button>
        </Form>

      </div>

      <ToastContainer />

      <Modal
        show={modalShow.show}
        onHide={() => setModalShow({ show: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmar envio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Sus datos son los siguientes:</p>
          
          <p style={{overflowWrap: 'break-word', whiteSpace: "pre-wrap", textAlign:"left", }}>Descripción:<br/>{bitacora.descripcion}</p>
          <p>Area: { bitacora.idArea ? areas.filter(function(element){
            
            return (element.idAreas === Number(bitacora.idArea))
          })[0].nombre : null}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => modalShow.callback({continua:false})}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={() => modalShow.callback({continua:true})}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default RegistrarBitacora
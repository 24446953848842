import Pagination from 'react-bootstrap/Pagination';

function MyPaginacion({rowsPerPage, totalElements, currentPage, setCurrentPage}) {

    const pageNumbers =[]

    

    for (let i = 1; i <= Math.ceil(totalElements/rowsPerPage); i++) {
        pageNumbers.push(i)
    }

    const onPrev = () => {
        setCurrentPage(currentPage - 1)
    }

    const onNext = () => {
        setCurrentPage(currentPage + 1)
    }

    const onSpecificPage = (number) => {
        setCurrentPage(number)
    }

  return (
    <Pagination>

      <Pagination.Prev onClick={onPrev} disabled={currentPage <= 1}/>

    {pageNumbers.map(number => (
        <Pagination.Item key={number} active={currentPage === number} onClick={() => onSpecificPage(number)}>
            {number}
        </Pagination.Item>
    ))}

      <Pagination.Next onClick={onNext} disabled={currentPage >= pageNumbers.length}/>

    </Pagination>
  );
}

export default MyPaginacion;
import { useState} from "react";
import axios from "axios";
import { AXIOS_HOST } from "../../../config/axios.config";
import { toast, ToastContainer } from "react-toastify";
import { obtainLocalStorageItem } from "../../../utilities/localStorage.utility";

import { Modal, Button, Col, Row, Form, Dropdown } from "react-bootstrap";


function CrearConvenio() {

  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);

  const [imageUrl, setImageUrl] = useState(null);

  const handleClose = () => {
    setShow(false);
    toast.warn("Se a cancelado la creación de usuario")

}

const handleShow = () => setShow(true);

const [convenio, setConvenio] = useState({
    nombre: "",
    descripcion: "",
    imagen:[],
    estado: 0
})

function handleChange(e) {
    const value = e.target.value;
    console.log(value, typeof value)

    const valueInt = parseInt(value)
    console.log(valueInt, typeof valueInt)
    
    
    setConvenio({
        ...convenio,
        [e.target.name]: value
    });

}

function handleCheckbox(e) {
    const value = e.target.checked;
    console.log(value, typeof value)

    setConvenio({
        ...convenio,
        [e.target.name]: value
    });

}

function handleFile(e) {

    setConvenio({
        ...convenio,
        imagen: e.target.files[0]
    });
    setImageUrl(URL.createObjectURL(e.target.files[0]))
}

const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        toast.error("Por favor rellene todos los campos")
    } else {
        handleShow()
    }

    setValidated(true);
}

const crearConvenio = async () => {
    const formData = new FormData();
    formData.append("nombre", convenio.nombre);
    formData.append("descripcion", convenio.descripcion);
    formData.append("file", convenio.imagen);
    formData.append("estado", convenio.estado);

    await axios.post(`${AXIOS_HOST}/convenios/add`, formData, {
        headers: {
            token: obtainLocalStorageItem("token"),
            "Content-Type": "multipart/form-data",
        },
    }).then(res => {
        console.log(res.data)
        setShow(false)
        toast.success("Convenio creado con éxito")
    }).catch(err => {
        console.log(err)
        toast.error("Error al crear el convenio")
    })
}


  return (
    <div className='container'>
        <h2>Creacion convenio</h2>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
                <Form.Group as={Col} className="mb-3" controlId="formNombre">
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="Nombre"
                        name="nombre"
                        onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                        Por favor ingrese un nombre
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} className="mb-3" controlId="formDescripcion">
                    <Form.Label>Descripcion</Form.Label>
                    <Form.Control
                        required
                        as="textarea"
                        placeholder="Descripcion"
                        name="descripcion"
                        onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                        Por favor ingrese una descripcion
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} className="mb-3" controlId="formImagen">
                    <Form.Label>Imagen</Form.Label>
                    <Form.Control
                        required
                        type="file"
                        placeholder="Imagen"
                        name="imagen"
                        onChange={handleFile}
                    />
                    <Form.Control.Feedback type="invalid">
                        Por favor ingrese una imagen
                    </Form.Control.Feedback>
                </Form.Group>

                <Col className="mb-3">
                    {imageUrl ? (
                        <>
                            {/*<Button variant="danger" onClick={() => setImageUrl(null)}>
                                Eliminar imagen
                            </Button>*/}
                            <img
                                src={imageUrl}
                                alt="imagen"
                                width={"200"}
                            />
                        </>
                    ) : <p>Seleccione una imagen ({">= 350 x 350"})</p>}
                </Col>

                <Form.Group as={Col} className="mb-3" controlId="formEstado">
                    <Form.Label>Estado</Form.Label>
                    <Form.Switch
                        type="checkbox"
                        name="estado"
                        onChange={handleCheckbox}
                    />
                    <Form.Control.Feedback type="invalid">
                        Por favor ingrese un estado
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>

            <Button variant="primary" type="submit">
                Crear
            </Button>
        </Form>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Creacion convenio</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Está seguro que desea crear el convenio?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={crearConvenio}>
                    Continuar
                </Button>
            </Modal.Footer>
        </Modal>

        <ToastContainer />
    
    </div>
  )
}

export default CrearConvenio
import axios from "axios"
import { useEffect, useState } from "react"
import { Carousel, Table, Button, Row, Modal } from "react-bootstrap"
import { AXIOS_HOST } from "../../../config/axios.config"
import { obtainLocalStorageItem } from "../../../utilities/localStorage.utility"
import Pagination from "../../../Components/Pagination/Pagination"
import Collapse from 'react-bootstrap/Collapse';
import EditNoticias from "./editNoticias"

import { ToastContainer, toast } from "react-toastify"

function ShowNoticias() {

  const [noticias, setNoticias] = useState([])
  const [baseNoticias, setBaseNoticias] = useState([])
  const [editar, setEditar] = useState({ show: false })

  //paginacion
  const totalNoticias = noticias.length
  const [rowsPerPage, setRowsPerPage] = useState(6)
  const [currentPage, setCurrentPage] = useState(1)
  const lastIndex = currentPage * rowsPerPage
  const firstIndex = lastIndex - rowsPerPage

  const [currentIndex, setCurrentIndex] = useState(null)

  const [confirmEnableDisable, setConfirmEnableDisable] = useState({
    show: false, idNoticia: null, titulo: null
  })

  const handleIndex = (id) => {
    if (currentIndex === id) {
      setCurrentIndex(null)
    } else {
      setCurrentIndex(id)
    }
  }


  const loadData = async () => {
    axios.get(`${AXIOS_HOST}/noticias/`).then(res => {
      console.log(res.data)
      setNoticias(res.data)
      setBaseNoticias(res.data)
    }).catch((err) => {
      console.log(err)
    })
  }

  const axiosEdit = (noticia) => {
    axios.put(`${AXIOS_HOST}/noticias/editar`, noticia, { headers: { token: obtainLocalStorageItem("token") } }).then(res => {
      console.log(res.data)
      toast.success("Noticia editada con exito")
      loadData()
    }).catch((err) => {
      console.log(err)
      toast.error("Error al editar noticia")
    })
  }

  const axiosEnableDisable = (noticia) => {
    console.log(noticia)
    if (noticia.active) {
      axios.put(`${AXIOS_HOST}/noticias/disable`, {idNoticia: noticia.idNoticia}, { headers: { token: obtainLocalStorageItem("token") } }).then(res => {
        toast.success("Noticia desactivada con exito")
        loadData()
      }).catch((err) => {
        console.log(err)
        toast.error("Error al desactivar noticia")
      })
    } else {
      axios.put(`${AXIOS_HOST}/noticias/enable`, {idNoticia: noticia.idNoticia}, { headers: { token: obtainLocalStorageItem("token") } }).then(res => {
        toast.success("Noticia activada con exito")
        loadData()
      }).catch((err) => {
        console.log(err)
        toast.error("Error al activar noticia")
      })
    }

    setConfirmEnableDisable({ show: false })
  }

  const handleEdit = (noticia) => {
    setEditar({
      show: true, noticia: noticia, callback: (data) => {
        if (data.continua) {
          axiosEdit(data.noticia)
        }
        setEditar({ show: false })
      }
    })
  }

  useEffect(() => {

    loadData()

  }, [])

  //Editar noticia


  return (
    <div className="container">
      <h1>Novedades de la Pagina</h1>

      <Table striped bordered hover style={{ textAlign: "left" }}>
        <thead>
          <tr>
            <th>Titulo</th>
            <th>Descripcion</th>
            <th>Fecha</th>
            <th>Acciones</th>

          </tr>
        </thead>
        <tbody>
          {noticias.map((noticia) => {

            const fechaISO = new Date(noticia.createdAt)
            const hora = fechaISO.toLocaleDateString('es-CL', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false });


            return (

              <tr key={noticia.idNovedad} onClick={() => handleIndex(noticia.idNovedad)}>
                <td>{noticia.titulo}</td>
                <td ><p className="multiline-ellipsis">{noticia.texto}</p></td>
                <td>{`${hora}`}</td>
                <td>
                  <div class="d-flex flex-wrap align-items-center">
                    <Button onClick={() => handleEdit(noticia)}>Editar</Button>
                    <Button className="btn btn-warning"
                    onClick={() => setConfirmEnableDisable({
                      show:true, 
                      idNoticia: noticia.idNovedad, 
                      titulo:noticia.titulo, 
                      isActive: noticia.active})}>{noticia.active ? "Desactivar" : "Activar"}</Button>
                  </div>
                </td>

              </tr>




            )
          }).slice(firstIndex, lastIndex)}
        </tbody>

      </Table>

      {editar.show ?
        <EditNoticias show={editar.show} onHide={() => { toast.warn("Se cancelo la edición"); setEditar({ show: false }) }} noticia={editar.noticia} callback={editar.callback} />
        : null}

      <ToastContainer />
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Pagination
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalElements={totalNoticias}
        />
      </div>

      <Modal show={confirmEnableDisable.show} onHide={() => setConfirmEnableDisable(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{confirmEnableDisable.isActive ? "¿Desea desactivar esta noticia?" : "¿Desea activar esta noticia?"}</p>
          <p>Titulo noticia: {confirmEnableDisable.titulo}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {setConfirmEnableDisable(false); toast.warn("Se cancelo la acción")}}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={() => { axiosEnableDisable({idNoticia:confirmEnableDisable.idNoticia, active: confirmEnableDisable.isActive}) }}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}

export default ShowNoticias
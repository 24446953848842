import axios from "axios"
import { useEffect, useState } from "react"
import { Button, Modal, Table } from "react-bootstrap"
import { toast, ToastContainer } from "react-toastify"
import { AXIOS_HOST } from "../../../config/axios.config"
import { obtainLocalStorageItem } from "../../../utilities/localStorage.utility"

import Pagination from "../../../Components/Pagination/Pagination"
import CrearBeneficiosTipos from "./crearBeneficiosTipos"

function ShowBeneficios() {

  const [modalShow, setModalShow] = useState(false);
  const [modalStatusShow, setModalStatusShow] = useState({ show: false, beneficioAntes: null, callback: null });

  const [beneficios, setBeneficios] = useState([])

  const [baseBeneficios, setBaseBeneficios] = useState([])

  const [tiposBeneficios, setTiposBeneficios] = useState([{ nombre:""}])

  //datos para paginacion
  const totalBeneficios = beneficios.length

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)

  const lastIndex = currentPage * rowsPerPage
  const firstIndex = lastIndex - rowsPerPage


    const  obtenerBeneficios = async () => {
        await axios.get(`${AXIOS_HOST}/beneficios/`).then(res => {
            console.log(res.data)
            setBeneficios(res.data.beneficios)
            setBaseBeneficios(res.data.beneficios)
        }).catch(err => console.log(err))
    }

    const obtenerTipos = async () => {
        await axios.get(`${AXIOS_HOST}/beneficios/tipos`).then(res => {
            console.log(res.data.tiposBeneficios)
            setTiposBeneficios(res.data.tiposBeneficios)
        }
        ).catch(err => console.log(err))
    }


  useEffect(() => {

    obtenerTipos()
    obtenerBeneficios()

    /*
    axios.get(`${AXIOS_HOST}/beneficios/`).then(res => {
      console.log(res.data)
      setBeneficios(res.data.beneficios)
      setBaseBeneficios(res.data.beneficios)
    })*/
/*
    axios.get(`${AXIOS_HOST}/beneficios/tipos`).then(res => {
      console.log(res.data.tiposBeneficios)
      setTiposBeneficios(res.data.tiposBeneficios)
    }
    )*/
  }, [])


  const handleStatus = (beneficioAntes) => {
    setModalStatusShow({
      show: true,
      beneficioAntes: beneficioAntes,
      callback: async (data) => {
        if (data.continua) {
          let operacion = data.beneficio.estado ? "delete" : "patch";
          let url = `${AXIOS_HOST}/beneficios/${data.beneficio.estado ? "disable" : "enable"}`;
          let respuesta = { status: false }

          if (operacion === "delete") {
            respuesta = await axios.delete(url, {data: {id_beneficio : beneficioAntes.id_beneficio} ,headers: { token: obtainLocalStorageItem("token") } }).then(res => {
              //console.log(res.data);
              toast.success(`El beneficio ${data.beneficio.nombre} ha sido desactivado con éxito`);
              return res.data;
            }).catch(err => {
              console.log(err);
              toast.error(`Error al desactivar el beneficio ${data.beneficio.nombre}`);
            });
          } else {
            respuesta = await axios.patch(url, beneficioAntes, { headers: { token: obtainLocalStorageItem("token") } }).then(res => {
              //console.log(res.data);
              toast.success(`El beneficio ${data.beneficio.nombre} ha sido activado con éxito`);
              return res.data;
            }).catch(err => {
              console.log(err);
              toast.error(`Error al activar el beneficio ${data.beneficio.nombre}`);
            });
          }
          if (respuesta.status === true) {
            let tmpBeneficios = JSON.parse(JSON.stringify(beneficios))
            tmpBeneficios = tmpBeneficios.map(beneficio => {
              if (beneficio.id_beneficio === data.beneficio.id_beneficio) {
                beneficio.estado = !data.beneficio.estado

              }
              return beneficio
            })

            let tmpBaseBeneficios = JSON.parse(JSON.stringify(baseBeneficios))
            tmpBaseBeneficios = tmpBeneficios.map(beneficio => {
              if (beneficio.id_beneficio === data.beneficio.id_beneficio) {
                beneficio.estado = !data.beneficio.estado
              }
              return beneficio
            })
            setBeneficios(tmpBeneficios)
            setBaseBeneficios(tmpBaseBeneficios)
          }
          setModalStatusShow(false)
        }
      }
    })

  }


  return (
    <div className="container">
      <h2>Beneficios</h2>

      {baseBeneficios.length === 0 && tiposBeneficios.length === 0 ? <h3>No hay beneficios</h3> :
        <Table striped bordered style={{ textAlign: "left" }}>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Descripción</th>
              <th>Tipo de beneficio</th>
              <th>Estado</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {baseBeneficios.slice(firstIndex, lastIndex).map((beneficio, index) => {
              //console.log(tiposBeneficios.find(tipo =>  beneficio.id_tipoBeneficio === tipo.id_tipoBeneficio).nombre)
              //console.log(tiposBeneficios)
              //console.log(beneficio.nombre)
              return (
                <tr key={index}>
                  <td>{beneficio.nombre}</td>
                  <td><p className="multiline-ellipsis">{beneficio.descripcion}</p></td>
                  {/*<td>{tiposBeneficios.find(tipo => tipo.id_tipoBeneficio === beneficio.id_tipoBeneficio)["nombre"] || "Cargando"}</td>*/}
                  <td>{tiposBeneficios.find(tipo => tipo.id_tipoBeneficio === beneficio.id_tipoBeneficio) ? tiposBeneficios.find(tipo => tipo.id_tipoBeneficio === beneficio.id_tipoBeneficio)["nombre"] : "Cargando"}</td>
                  <td>{beneficio.estado ? "Activo" : "Inactivo"}</td>
                  <td>
                    <Button variant="primary"
                    //onClick={() => handleEdit(beneficio)}
                    >Editar</Button>
                    <Button variant="danger"
                      onClick={() => handleStatus(beneficio)}
                    >Cambiar estado</Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>

      }

      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Pagination
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalElements={totalBeneficios}
        />
      </div>

      {modalStatusShow.show ?
        <Modal show={modalStatusShow.show} onHide={() => setModalStatusShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Cambiar estado</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>¿Está seguro que desea cambiar el estado del beneficio?</p>
            <p>El beneficio <b>{modalStatusShow.beneficioAntes.nombre}</b> pasará de estar <b>{modalStatusShow.beneficioAntes.estado ? "Activo" : "Inactivo"}</b> a <b>{modalStatusShow.beneficioAntes.estado ? "inactivo" : "activo"}</b></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalStatusShow(false)}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={() => modalStatusShow.callback({ continua: true, beneficio: modalStatusShow.beneficioAntes })}>
              Continuar
            </Button>
          </Modal.Footer>
        </Modal> : null
      }

      <ToastContainer />
    </div>
  )
}

export default ShowBeneficios
import { Modal, Button, Col, Row, Form, Dropdown } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "axios";
import { AXIOS_HOST } from "../../../config/axios.config";
import { toast, ToastContainer } from "react-toastify";
import { obtainLocalStorageItem } from "../../../utilities/localStorage.utility";

function CrearBeneficios() {

  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);

  const [imageUrl, setImageUrl] = useState(null);

  const handleClose = () => {
    setShow(false);
    toast.warn("Se a cancelado la creación de usuario")

}
const handleShow = () => setShow(true);

const [beneficio, setBeneficio] = useState({
  nombre: "",
  descripcion: "",
  tipoBeneficio: "",
  imagen:[],
  estado: 1
})

const [tiposBeneficios, setTiposBeneficios] = useState([])

useEffect(() => {
  axios.get(`${AXIOS_HOST}/beneficios/tipos`).then(res => {
    console.log(res.data.tiposBeneficios)
    setTiposBeneficios(res.data.tiposBeneficios)
  })
}, [])

function handleChange(e) {
  const value = e.target.value;
  console.log(value, typeof value)

  const valueInt = parseInt(value)
  console.log(valueInt, typeof valueInt)
  
  
  setBeneficio({
    ...beneficio,
    [e.target.name]: value
  });

}

function handleSelect(e) {
  const valueInt = parseInt(e.target.value)
  console.log(valueInt, typeof valueInt)

  setBeneficio({
    ...beneficio,
    [e.target.name]: valueInt
  });

}

function handleCheckbox(e) {
  const value = e.target.checked;
  setBeneficio({
    ...beneficio,
    [e.target.name]: value
  });
}

const handleFileInput = (e) => {

  setBeneficio({
      ...beneficio,
      imagen: e.target.files[0]
  })
  setImageUrl(URL.createObjectURL(e.target.files[0]));

}

const handleSubmit = (e) => {
  e.preventDefault()
  const form = e.currentTarget;
  if (form.checkValidity() === false) {
    e.preventDefault();
    e.stopPropagation();
    toast.error("Debe completar todos los campos")
  } else {
    handleShow()
  }

  setValidated(true);

}

const createBeneficio = async () => {

  console.log(beneficio)

  const formData = new FormData()
  formData.append("nombre", beneficio.nombre)
  formData.append("descripcion", beneficio.descripcion)
  formData.append("tipoBeneficio", beneficio.tipoBeneficio)
  formData.append("estado", beneficio.estado)
  formData.append("file", beneficio.imagen)

  const res = await axios.post(`${AXIOS_HOST}/beneficios/create`, formData, { headers: { token: obtainLocalStorageItem("token"), 'Content-Type': 'multipart/form-data' }, })
      .then((res) => {
          console.log(res.data)
          setShow(false)
          toast.success("Beneficio creado con éxito")
      })
      .catch((err) => {

          toast.error("Error al crear beneficio")
      })

}

  return (
    <div className="container">
      <h2>Creación de beneficio</h2>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
        <Form.Group as={Col} className="mb-3" controlId="formNombre">
          <Form.Label>Nombre</Form.Label>
          <Form.Control required type="text" placeholder="Nombre" name="nombre" onChange={handleChange} />
          <Form.Control.Feedback type="invalid">
            Debe ingresar un nombre
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} className="mb-3" controlId="formDescripcion">
          <Form.Label>Descripción</Form.Label>
          <Form.Control required as="textarea" placeholder="Descripción" name="descripcion" onChange={handleChange} />
          <Form.Control.Feedback type="invalid">
            Debe ingresar una descripción
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} className="mb-3" controlId="formTipoBeneficio">
          <Form.Label>Tipo de beneficio</Form.Label>
          <Form.Select required name="tipoBeneficio" onChange={handleSelect}>
            <option value="">Seleccione un tipo de beneficio</option>
            {tiposBeneficios.map((tipo) => (
              <option value={tipo.id_tipoBeneficio}>{tipo.nombre}</option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Debe seleccionar un tipo de beneficio
          </Form.Control.Feedback>
        </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} className="mb-3" controlId="formImagen">
            <Form.Label>Imagen</Form.Label>
            <Form.Control required type="file" name="imagen" onChange={handleFileInput} />
            <Form.Control.Feedback type="invalid">
              Debe seleccionar una imagen
            </Form.Control.Feedback>
          </Form.Group>

          <Col className="mb-3">
            {imageUrl ? 
            <>
              {/*<Button variant="danger" onClick={() => setImageUrl(null)}>Eliminar imagen</Button>*/}
              <img src={imageUrl} alt="" width="200" />
            </>
            : <p>Seleccione una imagen ({">= 350 x 350"})</p>
            }
          </Col>


          <Form.Group as={Col} className="mb-3" controlId="formEstado">
            <Form.Label>Estado</Form.Label>
            <Form.Switch type="checkbox" name="estado" onChange={handleCheckbox} />
            <Form.Control.Feedback type="invalid">
              Debe seleccionar un estado
            </Form.Control.Feedback>
          </Form.Group>

        </Row>

        <Button variant="primary" type="submit">
          Crear
        </Button>
      </Form>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmación</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Está seguro que desea crear el beneficio?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={() => {createBeneficio()}}>
            Crear
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />

    </div>
  )
}

export default CrearBeneficios
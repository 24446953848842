import { Col, Form, Button, Row } from 'react-bootstrap';
import { useState } from "react";

import axios from "axios";

import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../components.css"
import { Input, Spacer, Text, Textarea } from '@nextui-org/react';

import 'bootstrap/dist/css/bootstrap.min.css';
import "./contactenos.css"
import { AXIOS_HOST } from '../../config/axios.config';

export default function Contactenos() {

    const [state, setState] = useState({
        nombre: "",
        telefono: "",
        email: "",
        mensaje: ""
    })

    function handleState(event) {
        const value = event.target.value
        setState({
            ...state,
            [event.target.name]: value
        })
    }

    const [validated, setValidated] = useState(false);

    const sendMessage = async (event) => {
        await axios.post(`${AXIOS_HOST}/contactenos/create`, state).then((res) => {
            event.target.reset()
            toast.success(`se a enviado su mensaje sr/a: ${state.nombre}`);
            setValidated(false);
            console.log(res);
        }).catch((err) => {
            console.log(err);
            toast.error("Error al enviar el mensaje, vuelva a intentarlo en un rato");
        })
    }

    const notify = (event) => {

        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            toast.warn("Por favor, rellene todos los campos");
        } else {
            sendMessage(event)
        }

    }



    return (
        <div >
            <Text color="#46c3c7" h2 size={40} css={{ textAlign: "left" }} style={{ padding: "5%" }}>Contáctanos</Text>

            <Col>
                <Form noValidate validated={validated} onSubmit={notify}>
                    <Row>
                        <Form.Group controlId="FormNombre" style={{}}>
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                className='FormInputsContactenos'
                                size="lg"
                                type="text"
                                placeholder="Nombre"
                                onChange={handleState}
                                name="nombre"
                                required
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group controlId="FormTelefono" style={{}}>
                            <Form.Label>Fono</Form.Label>
                            <Form.Control
                                className='FormInputsContactenos'
                                size="lg"
                                type="text"
                                placeholder="Fono (+56 9 XXXX XXXX)"
                                onChange={handleState}
                                name="telefono"
                                required
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group controlId="FormEmail" style={{}}>
                            <Form.Label>Email</Form.Label>
                            <Form.Control

                                className='FormInputsContactenos'
                                size="lg"
                                type="email"
                                placeholder="Email (ejemplo@gmail.com)"
                                onChange={handleState}
                                name="email"
                                required
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group controlId="FormMensaje" style={{}}>
                            <Form.Label>Mensaje</Form.Label>
                            <Form.Control
                                className='FormTextareaContactenos'
                                as="textarea"

                                rows={6}
                                size="lg"
                                placeholder="Mensaje"
                                onChange={handleState}
                                name="mensaje"
                                required
                            />
                        </Form.Group>
                    </Row>

                    <Button className='FormButtonSubmit' type="submit" size="lg" >Enviar</Button>

                </Form>
            </Col>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                transition={Flip}
                limit={6}
                newestOnTop
                closeOnClick
                draggable
                
                theme="light"
            />
        </div>
    )

}
import { Modal, Button, Col, Row, Form, Dropdown } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "axios";
import { AXIOS_HOST } from "../../../config/axios.config";
import { toast, ToastContainer } from "react-toastify";

export default function EditarProfesor({profesor,avatarimg , show, handleClose, callback, clases, editing}) {
  const [profesorEdit, setProfesorEdit] = useState({
    nombre: "" ,
    apellido: "" ,
    rut: "" ,
    descripcion: "",
    avatarIMG: "",
    clases: [],
    activo: "",
  })

  useEffect(() => {
    setProfesorEdit({
        nombre: profesor.nombre ,
        apellido: profesor.apellido ,
        rut: profesor.rut ,
        descripcion: profesor.descrip,
        avatarIMG: avatarimg,
        clases: profesor.clases || [],
        activo: profesor.activo,
    })
  }, [])

  const [archivo, setArchivo] = useState("")
  const [preview, setPreview] = useState("")

  useEffect(() => {
    if (archivo) {
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log("use", reader.result)
        setPreview(reader.result);
        setProfesorEdit({
            ...profesorEdit,
            avatarIMG: reader.result
        })
      };
      reader.readAsDataURL(archivo);
    } else {
      setPreview(null)
    }
  }, [archivo]);

  function handleChange(e) {
    const value = e.target.value;
    setProfesorEdit({
      ...profesorEdit,
      [e.target.name]: value
    });
  }

  const handleFileChange = (e) => {
    setArchivo(e.target.files[0])
  }

  const handleCheckbox = (e) => {
        console.log(profesorEdit)
    if (e.target.checked) {
        setProfesorEdit({
            ...profesorEdit,
            [e.target.name]: [...profesorEdit.clases, e.target.value]
        })
    } else {
        setProfesorEdit({
            ...profesor,
            [e.target.name]: [...profesorEdit.clases.filter((rol) => rol !== e.target.value)]
        })
    }
}

  const handleEdit = async () => {

    

    await axios.put(`${AXIOS_HOST}/profes/update`, profesorEdit).then((res) => {
      console.log(res.data)
      toast.success("Profesor editado con exito")
        callback({continua: true, profesor: profesorEdit})
      handleClose()
    }).catch((err) => {
        console.log(err)
        toast.error("Error al editar profesor")
        callback({continua: false})
        })

  }

  return (
    <>
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Editar Profesor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formNombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control type="text" placeholder="Nombre" name="nombre" value={profesorEdit.nombre} onChange={handleChange} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="formApellido">
                <Form.Label>Apellido</Form.Label>
                <Form.Control type="text" placeholder="Apellido" name="apellido" value={profesorEdit.apellido} onChange={handleChange} />
            </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col>
            <Form.Group className="mb-3" controlId="formRut">
                <Form.Label>Rut</Form.Label>
                <Form.Control type="text" placeholder="Rut" name="rut" value={profesorEdit.rut} onChange={handleChange} />
            </Form.Group>
            </Col>
            <Col>
            <Form.Group className="mb-3" controlId="formClases">
                <Form.Label>Clases</Form.Label>
                <Dropdown autoClose={"outside"} >
              <Dropdown.Toggle  id="dropdown-basic" style={{width:"100%", backgroundColor:"white", border:"1px solid #ced4da", color:"#6c757d"}}>
                {profesorEdit.clases.length > 0 ? "Clases Seleccionadas" : "Seleccione Clases"}
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ minWidth: "200px", paddingLeft:"5px" }}>
                {clases.map((clase) => {
                return(
                  <Form.Check
                    key={clase.id}
                    type="checkbox"
                    id={`checkbox-${clase.id}`}
                    label={clase.nombre}
                    name="clases"
                    checked={profesorEdit.clases.includes(`${clase.id}`)}
                    value={clase.id}
                    onChange={handleCheckbox}
                    
                  />
                )})}
              </Dropdown.Menu>
            </Dropdown>
            </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col>
            <Form.Group className="mb-3" controlId="formDescripcion">
                <Form.Label>Descripción</Form.Label>
                <Form.Control type="text" as={"textarea"} placeholder="Descripcion" name="descripcion" defaultValue={profesorEdit.descripcion} onChange={handleChange} />
            </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Avatar</Form.Label>
                <Form.Control type="file" accept="image/*" placeholder="Avatar" name="avatar" onChange={handleFileChange} />
            </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col>
            <img src={profesorEdit.avatarIMG} alt="preview" style={{width:"100%"}}/>
            </Col>
        </Row>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
            Close
        </Button>
        <Button variant="primary" onClick={handleEdit}>Editar</Button>
        </Modal.Footer>
    </Modal>

    <ToastContainer />
    </>
    );
}
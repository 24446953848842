import { useState, useEffect } from 'react'
import axios from 'axios'

import { AXIOS_HOST } from '../config/axios.config'
import HeaderV2 from '../Components/Header/headerV2'

import "./publicStyle.css"
import { Button, Card, Col, Row } from 'react-bootstrap'
import ModalInformacion from '../Components/profesores/ModalInformacion'

function Profesores() {

    const [modalShowInfo, setModalShowInfo] = useState({show:false})

    const [profesores, setProfesores] = useState([])

    const loadProfesores = async () => {
        const response = await axios.get(`${AXIOS_HOST}/profes/allpublic`)
        console.log(response.data.profesores)
        setProfesores(response.data.profesores)
    }

    useEffect(() => {
        loadProfesores()
    }, [])

    return (
        <div className='MyBackground'>
            <HeaderV2 />

            <div className='container' style={{ backgroundColor: "aliceblue" }}>
                <div className='row'>
                    <div className='col-12'>
                        <h1>Profesores</h1>

                        <Row style={{ textAlign: "center" }}>
                            {profesores.map((profesor) => (

                                <Col xs={12} md={6} lg={4} xl={4} style={{ padding: "10px", maxWidth: "max-content", marginLeft: "auto", marginRight: "auto" }}>

                                    <Card style={{ maxWidth: "350px", aspectRatio: 2 / 3 }}>


                                        <Card.Img src={`${profesor.avatarIMG}`} style={{ objectFit: "cover", width: "100%", height: "100%" }}>

                                        </Card.Img>
                                        <Card.ImgOverlay style={{ display: "flex", flexDirection: "column", padding: 0, textAlign: "left" }}>


                                            <div className='MyGlassmorphism' style={{ marginTop: "auto", borderBottomLeftRadius: "4px", borderBottomRightRadius: "4px" }}>
                                                <Card.Title style={{ paddingLeft: "10px", paddingTop: "5px", color: "white", display: "inline" }}>{profesor.nombre} {profesor.apellido}</Card.Title>
                                                <Card.Text className='multiline-ellipsis' style={{ paddingLeft: "5px", color: "white" }}>{profesor.descrip}</Card.Text>

                                                <Card.Footer style={{ padding: "5px", backgroundColor: "transparent", textAlign: "right" }}>
                                                    <Button variant="outline-light" style={{ borderRadius: "20px", padding: "5px 10px", display: "inline-block" }} onClick={() => {setModalShowInfo({show:true, profesor:profesor})}}>Ver más</Button>

                                                </Card.Footer>
                                            </div>
                                        </Card.ImgOverlay>
                                    </Card>

                                </Col>

                            ))}
                        </Row>

                    </div>
                </div>
            </div>
                                <ModalInformacion onHide={() => setModalShowInfo({show:false})} profesor={modalShowInfo.profesor} show={modalShowInfo.show}   />
        </div>
    )
}

export default Profesores
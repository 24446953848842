import React from "react";

import { useState, useEffect } from "react";
import { Modal, Button, Row , Col} from "react-bootstrap";



export default function ModalProfesores(props){
    
        return (
          <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
              Confirmacion de información
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4>¿Desea enviar esta información?</h4>

              <ul>
              {props.funcion.info ? Object.keys(props.funcion.info).map((key) =>{
                return(
                  <li style={{whiteSpace: "pre-wrap"}} key={key}>
                  <Row> 

                    <Col md={2}>{key}</Col> : <Col md={8} >{props.funcion.info[key].includes("data:image") ? 
                    <img src={props.funcion.info[key]} alt="preview" style={{ maxHeight: "140px", objectFit: "cover", aspectRatio: 1 / 1, border: "dotted", borderRadius: 100 }} />
                    
                    : props.funcion.info[key]}  </Col> 
                  </Row></li>
                )
              }):""}
              </ul>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=>{props.onHide(); props.funcion.callback({continua:false})}}>Close</Button>
              <Button onClick={() => props.funcion.callback({continua:true})}>Aceptar</Button>
            </Modal.Footer>
          </Modal>
        );
      
    
}
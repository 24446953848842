import { useEffect, useState } from "react";
import { Modal, Form, Button, Col, Row } from "react-bootstrap";

export default function EditHora({ show, onHide, horario, clases, callback }) {
    const [editHorario, setEditHorario] = useState({
        idhorarios: horario.idhorarios,
        dia: horario.dia,
        hora: horario.hora,
        horaFin: horario.horaFin,
        clase: horario.clase,
        status: horario.status,
        idclase: horario.idclase
    })


    const [validated, setValidated] = useState(false);

    function handleChange(e) {
        const value = e.target.value;
        console.log(value)
        setEditHorario({
            ...editHorario,
            [e.target.name]: value,
        });
    }

    function handleSelectChange(e) {
        const value = e.target.value;
        console.log(value)
        setEditHorario({
            ...editHorario,
            [e.target.name]: parseInt(value),
            clase: clases.find((clase) => clase.id === parseInt(value)).nombre
        });
    }

    function handleSwitch(e) {
        const value = e.target.checked;
        console.log(value)
        setEditHorario({
            ...editHorario,
            [e.target.name]: value,
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            console.log(form.checkValidity())
        } else {
            console.log(editHorario)
            callback(editHorario)
            onHide()
        }
    }

    console.log(horario)
    return (
        <Modal
            show={show}
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Editar Horario
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>

                    <Form.Group as={Row} className="mb-3" controlId="formDia">
                        <Form.Label column md={3}>Día</Form.Label>
                        <Col md={6}>
                            <Form.Select required name="dia" defaultValue={editHorario.dia} onChange={(e) => handleChange(e)}>
                                <option value="">Seleccione Día</option>
                                <option value="Lunes">Lunes</option>
                                <option value="Martes">Martes</option>
                                <option value="Miercoles">Miercoles</option>
                                <option value="Jueves">Jueves</option>
                                <option value="Viernes">Viernes</option>
                                <option value="Sabado">Sabado</option>

                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                ingrese Dia
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formHora">
                        <Form.Label column md={3}>Hora inicio</Form.Label>
                        <Col md={6}>
                            <Form.Control required type="time" name="hora" placeholder="Ingrese hora" defaultValue={editHorario.hora} onChange={(e) => handleChange(e)} />
                            <Form.Control.Feedback type="invalid">
                                ingrese hora
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formHora">
                        <Form.Label column md={3}>Hora fin</Form.Label>
                        <Col md={6}>
                            <Form.Control required type="time" name="horaFin" placeholder="Ingrese hora" defaultValue={editHorario.horaFin} onChange={(e) => handleChange(e)} />
                            <Form.Control.Feedback type="invalid">
                                ingrese hora
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formClase">
                        <Form.Label column md={3}>clase</Form.Label>
                        <Col md={6}>
                            <Form.Select required name="idclase" defaultValue={editHorario.idclase} onChange={(e) => handleSelectChange(e)}>
                                <option value="">Seleccione Clase</option>
                                {clases ? clases.map((clase) => {
                                    return (
                                        <option key={clase.nombre} value={clase.id}>{clase.nombre}</option>
                                    )
                                }) : <option value={null}>Cargando...</option>}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Ingrese Clase
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formActivo">
                        <Form.Label column md={3}>¿Hora Activa?</Form.Label>
                        <Col md={6}>
                            <Form.Check

                                type="switch"
                                id="custom-switch"
                                name="status"
                                label={editHorario.status ? "Activa" : "Inactiva"}
                                defaultChecked={editHorario.status}
                                onChange={(e) => handleSwitch(e)}
                            />
                        </Col>
                    </Form.Group>

                    <Col sm={4} style={{ marginTop: "3rem" }}>
                    </Col>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => callback({continua:true, edited:editHorario})}>Enviar</Button>
                <Button onClick={onHide}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    )

}
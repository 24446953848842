import Col from 'react-bootstrap/Col';

import Row from 'react-bootstrap/Row';

import Logo from "../../../logos/Logo1.png"

import { Link, Outlet } from 'react-router-dom';

import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar';


import { FcSafe } from 'react-icons/fc';
import { ImUsers, ImUserPlus, ImClock, ImCalendar } from "react-icons/im"
import { CgUserList } from "react-icons/cg"
import { MdOutlineClass } from "react-icons/md"
import { TfiViewList } from "react-icons/tfi"
import { FaCalendarPlus } from "react-icons/fa"
import { AiOutlineTranslation } from "react-icons/ai"
import { HiOutlineUserGroup, HiOutlineUsers, HiOutlineUserAdd } from "react-icons/hi"
import { GrContact } from "react-icons/gr"

import { BsNewspaper } from "react-icons/bs"

import { TbLayoutSidebarLeftCollapse, TbLayoutSidebarRightCollapse } from "react-icons/tb"
import { Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { Avatar } from '@nextui-org/react';
import Image from 'react-bootstrap/Image'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import axios from 'axios';
import { AXIOS_HOST } from '../../../config/axios.config';
import { clearLocalStorage, obtainLocalStorageItem, persistLocalStorage } from '../../../utilities/localStorage.utility';



import "./style.css"

export default function NavegadorPersonal() {

    const [cerrarSesion , setCerrarSesion] = useState(false)


    const { collapseSidebar } = useProSidebar();
    const [collapsed, setCollapsed] = useState(true)

    useEffect(() => {
        document.title = 'Dashboard';
        if (window.innerWidth < 1000) {
            collapseSidebar(true)
            setCollapsed(false)
        }
    }, [])

    const [allows, setAllows] = useState([])
    const [userData, setUserData] = useState({
        nombre: "",
        avatar: ""
    })

    useEffect(() => {
        //Apikey??
        const inicio = async () => {
            await axios.post(`${AXIOS_HOST}/jwt/informacion`, { token: obtainLocalStorageItem("token") }).then((res) => {
                console.log(res.data)
                persistLocalStorage("rut", res.data.rut)
                setAllows(res.data.roles)
                setUserData({ nombre: `${res.data.nombre.split(" ")[0]} ${res.data.apellido.split(" ")[0]}`, avatar: res.data.avatar })
            })
        };
        inicio()
    }, [])

    return (
        <>

            <div style={{ display: 'flex', height: '100%' }}>
                <Sidebar className='mySidebar' width="240px" backgroundColor={"rgba(#fff, #fff, #fff, 0.1)"}>
                    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                        <a href='/'><img className='logo' height="auto" width="auto" alt='' src={Logo}></img></a>
                        <Menu iconShape="square" style={{ textAlign: "left", overflow: "auto" }}>

                            <div style={{ flex: "1 1 0%", marginBottom: "32px" }}>
                                <MenuItem icon={<FcSafe />} component={<Link to={"./inicio"} />}>Inicio</MenuItem>
                                <MenuItem icon={<GrContact size={20} />} component={<Link to="./vercontactenos" />}>Contactenos</MenuItem>
                                <SubMenu label="Beneficios" >
                                    <MenuItem component={<Link to="./showbeneficios" />}>Mostrar</MenuItem>
                                    <MenuItem component={<Link to="./crearbeneficio" />}>Crear</MenuItem>
                                    <MenuItem component={<Link to="./showtipobeneficio" />}>Mostrar tipo</MenuItem>

                                    <MenuItem component={<Link to="./crearconvenio" />}>Crear convenio</MenuItem>
                                    <MenuItem component={<Link to="./showconvenios" />}>Mostrar convenios</MenuItem>
                                </SubMenu>
                                <SubMenu label="Areas" >
                                    <MenuItem component={<Link to="./showareas" />}>Mostrar</MenuItem>
                                    <MenuItem component={<Link to="./createarea" />}>Crear</MenuItem>
                                </SubMenu>
                                {<SubMenu active="true" label="Usuarios" icon={<HiOutlineUserGroup size={"20px"} />} >
                                    <MenuItem icon={<HiOutlineUsers size={20} />} component={<Link to="./showusers" />}>Mostrar</MenuItem>
                                    <MenuItem icon={<HiOutlineUserAdd size={20} />} component={<Link to="./createUser" />}>Crear</MenuItem>
                                </SubMenu>}
                                <SubMenu label="Profesores" icon={<ImUsers size={"20px"} />}>
                                    <MenuItem icon={<CgUserList size={"20px"} />} component={<Link to="./showProfesores" />}>Mostrar
                                    </MenuItem>
                                    <MenuItem icon={<ImUserPlus size={"20px"} />} component={<Link to="./crearProfesor" />}>Crear</MenuItem>
                                </SubMenu>
                                <SubMenu label="Clases" icon={<MdOutlineClass size={"20px"} />}>
                                    <MenuItem icon={<TfiViewList size="20px" />} component={<Link to="./showclases" />}>Ver clases
                                    </MenuItem>
                                    <MenuItem component={<Link to="./añadirclase" />}>Añadir Clase</MenuItem>
                                    <MenuItem>Añadir Tipo</MenuItem>
                                </SubMenu>

                                <SubMenu label="Horarios" icon={<ImClock size={"20px"} />}>
                                    <MenuItem icon={<ImCalendar size={"20px"} />} component={<Link to="./showhorarios" />}>Ver horarios</MenuItem>
                                    <MenuItem icon={<FaCalendarPlus size={"20px"} />} component={<Link to="./crearhora" />}>Crear hora</MenuItem>
                                </SubMenu>

                                <SubMenu label="Bitacoras" icon={<AiOutlineTranslation size={"20px"} />}>
                                    <MenuItem component={<Link to="./verbitacoras" />}>Ver Bitacora</MenuItem>
                                    <MenuItem >Ver Datos Piscina</MenuItem>
                                    <MenuItem component={<Link to="./addbitacora" />}>Registrar Bitacora</MenuItem>
                                    <MenuItem>Registrar Valores</MenuItem>
                                </SubMenu>

                                <SubMenu label="Noticias" icon={<BsNewspaper size={20} />}>
                                    <MenuItem component={<Link to="./shownoticias" />}>Ver Noticias</MenuItem>
                                    <MenuItem component={<Link to="./crearnoticia" />}>Añadir Noticia</MenuItem>
                                </SubMenu>

                            </div>
                        </Menu>

                        <hr style={{ display: "flex", marginTop: "auto", justifyContent: "center", }}></hr>
                        <Menu style={{ paddingBottom: "20px" }}>
                            <Row >

                                <Col style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                    <Image roundedCircle crossOrigin="anonymous" src={`${AXIOS_HOST}/userAvatars/${userData.avatar}`} style={{ width: "50px" }} />
                                </Col>
                                <Col>
                                    <OverlayTrigger
                                        trigger="click"
                                        placement={"top"}
                                        show={cerrarSesion}
                                        onToggle={() => { setCerrarSesion(!cerrarSesion) }
                                        }
                                        overlay={
                                            <Popover id={`popover-positioned-top`}>
                                                <Popover.Header as="h3"><strong>¿Desea cerrar sesión?</strong></Popover.Header>
                                                <Popover.Body>
                                                    <Button variant="secondary" onClick={() => { setCerrarSesion(!cerrarSesion)}} style={{marginRight:"1px"}}>Cancelar</Button>
                                                    <Button variant="danger" onClick={() => { clearLocalStorage("token"); window.location.reload() }} style={{marginLeft:"1px"}}>Cerrar sesión</Button>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <span className='Nombre'>{userData.nombre}</span>

                                    </OverlayTrigger>
                                </Col>

                                <Col style={{ display: "flex", justifyContent: "center" }}>
                                    <Button style={{ backgroundColor: "transparent", borderColor: "transparent" }} onClick={() => { collapseSidebar(); setCollapsed(!collapsed) }}>
                                        {collapsed ? <TbLayoutSidebarLeftCollapse color='black' size={"30px"} /> : <TbLayoutSidebarRightCollapse color='black' size={30} />}
                                    </Button>
                                </Col>
                            </Row>
                        </Menu>
                    </div>
                </Sidebar>

                <div className='SecondedCont' style={{ paddingTop: "3rem", width: "100%", overflow: "auto", paddingBottom: "20px" }}>
                    <Outlet />
                </div>
            </div >




        </>

    )
}


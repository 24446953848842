import axios from "axios"
import { useEffect, useState } from "react"

import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Carousel from 'react-bootstrap/Carousel';
import Modal from "react-bootstrap/Modal"

import { AXIOS_HOST } from "../../../config/axios.config"
import { obtainLocalStorageItem } from "../../../utilities/localStorage.utility"
import PreviewNoticia from "./previewNoticia"


import "./crearStyle.css"
import { toast, ToastContainer } from "react-toastify"



function CrearNoticia() {

  const [filesUrls, setFilesUrls] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [preview, setPreview] = useState(false);

  const [modalShow, setModalShow] = useState({ show: false })


  const [noticia, setNoticia] = useState({
    titulo: "",
    texto: "",
    archivos: [],
    activeCaption: true
  })
  const [portada, setPortada] = useState("")

  const handleFileInput = (e) => {
    const files = e.target.files;
    const name = e.target.name;
    const urls = [];
    console.log(files.length)
    if (files.length === 0) {
      setFilesUrls([])
      return;
    };
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.onload = (e) => {
        urls.push({ url: e.target.result, tipo: files[i].type });
        if (urls.length === files.length) {
          setNoticia({
            ...noticia,
            [name]: files,
          });
          setFilesUrls(urls);
        }
      };
      reader.readAsDataURL(files[i]);
    }

    const Selector = Array.from(e.target.files);
    setSelectedFiles(Selector);
  };

  const handlePortada = (e) => {
    console.log(e.target.value)
    setPortada(e.target.value)
  }


  const handleInputChange = (e) => {
    setNoticia({
      ...noticia,
      [e.target.name]: e.target.value,
    })

    console.log(noticia)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append('titulo', noticia.titulo);
    formData.append('texto', noticia.texto);
    formData.append('portada', portada);
    formData.append('activeCaption', noticia.activeCaption);

    for (let i = 0; i < noticia.archivos.length; i++) {
      console.log(noticia.archivos[i])
      formData.append('file', noticia.archivos[i]);

    };

    console.log(noticia)
    console.log(filesUrls)
    axios
      .post(`${AXIOS_HOST}/noticias/create`, formData, { headers: { token: obtainLocalStorageItem("token"), 'Content-Type': 'multipart/form-data' }, })
      .then((res) => {
        console.log(res)
        toast.success("Noticia creada con exito")
      }).catch((err) => {
        console.log(err)
        toast.error("Error al crear noticia")
      })
  }

  const handleShowConfirm = (e) => {
    e.preventDefault()
    setModalShow({
      show: true,
      callback: ((data) => {
        if (data.continua) {
          handleSubmit(e)
          setModalShow({ show: false })
        }
        else {
          toast.warn("Creacion de noticia cancelada")
          setModalShow({ show: false })
        }
      })
    })

  }




  return (
    <div>
      <h1>{!preview ? "Crear Noticia" : "Preview Noticia"}</h1>

      <div className="container" style={{
        maxHeight: "350px",
        maxWidth: "900  px",

        textAlign: "center",
        marginBottom: "2rem"
      }}>
        <Carousel interval={null} variant="dark">
          {filesUrls.length !== 0 ? filesUrls.map((url, index) => (
            <Carousel.Item key={index}>
              {url.tipo.includes("video") ? <video src={url.url} controls style={{ maxHeight: "350px", maxWidth: "70vw", aspectRatio: "2/1", zIndex: "5" }} /> :
                <img

                  src={url.url}
                  alt={`Imagen ${index}`}
                  style={{ maxHeight: "350px", aspectRatio: "2/1" }}
                />}
            </Carousel.Item>
          )) : <img src="https://via.placeholder.com/700x350 " alt="placeholder" />}
        </Carousel>
      </div>

      <div className="container  justify-content-center align-items-center" >


        {!preview ? <Form onSubmit={handleShowConfirm} >
          <Row>
            <Form.Group md={6} className="file" as={Col} controlId="formImageInput">
              <Form.Label>Imagenes</Form.Label>
              <Form.Control

                accept='image/png, image/jpeg, video/mp4'
                type="file"
                name="archivos"
                onChange={handleFileInput}
                multiple
                required
              />

            </Form.Group>

            {/* Se creara un display con el cual se seleccionara el archivo que sera la portada de la noticia */}
            <Form.Group md={6} className="file" as={Col} controlId="formImageInput">
              <Form.Label>Portada</Form.Label>
              <Form.Select required aria-label="Default select example" onChange={handlePortada} defaultValue={portada}>
                <option value="">Seleccionar</option>
                {selectedFiles.map((url, index) => (
                  <option key={index} value={url.name}>{`${url.name}`}</option>
                ))}
              </Form.Select>
            </Form.Group>


          </Row>
          <Row style={{paddingTop: "1rem"}}>
            <Form.Group md={6} as={Col} controlId="formGridTitulo">
              <Form.Label>Titulo</Form.Label>
              <Form.Control
                defaultValue={noticia.titulo}
                type="text"
                name="titulo"
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group md={3} className="activeCaption" as={Col} controlId="formActiveCaption">
              <Form.Label>¿Desea activar caption?</Form.Label>
              <Form.Check
                type="switch"
                id="custom-switch"
                defaultChecked={noticia.activeCaption}
                label={noticia.activeCaption ? "Activado" : "Desactivado"}
                onChange={(e) => setNoticia({ ...noticia, activeCaption: e.target.checked ? 1 : 0 })}
              />
            </Form.Group>
          </Row>
          <Form.Group md={12} as={Col} controlId="formGridDescripcion" style={{ textAlign: "center", paddingTop: "1rem", paddingBottom: "1rem" }}>
            <Form.Label>Texto</Form.Label>
            <Form.Control
              defaultValue={noticia.texto}
              name="texto"
              as="textarea"
              onChange={handleInputChange}
              rows={5}
              required
            />
          </Form.Group>

          <Button type="submit">Enviar</Button>
        </Form>

          :
                  
          {/*<PreviewNoticia
            titulo={noticia.titulo}
            texto={noticia.texto}
                />*/}}

        {/*<Button onClick={() => setPreview(!preview)} style={{ marginTop: "2rem" }}>{preview ? "Editar" : "Preview"}</Button>*/}
      </div>

      <Modal show={modalShow.show} onHide={() => setModalShow({ show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Esta seguro que desea crear esta noticia?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => modalShow.callback({ continua: false })}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={() => modalShow.callback({ continua: true })}>
            Confirmar
          </Button>
        </Modal.Footer>

      </Modal>



      <ToastContainer />
    </div>
  )
}

export default CrearNoticia
import React from "react";
import axios from "axios";

import { useEffect, useState } from "react";

import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image'
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import { toast, ToastContainer } from "react-toastify";
import StatusProfesor from "./statusProfesor";
import EditarProfesor from "./EditarProfesor";
import { AXIOS_HOST } from "../../../config/axios.config";

const HOST = process.env.REACT_APP_BCE_HOST || "http://localhost:3001"

export default function ShowProfesores() {


    const [info, setInfo] = useState([])
    const [baseInfo, setBaseInfo] = useState([])

    const [clases, setClases] = useState([])

    const [statusShow, setStatusShow] = useState({ show: false });
    const [editShow, setEditShow] = useState({ show: false });


    const loadProfesores = async () => {
        await axios.get(`${HOST}/profes/all`).then((res) => {
            
            setInfo(res.data.profesores)
            setBaseInfo(res.data.profesores)
        })
    }

    const loadClases = async () => {
        await axios.get(`${AXIOS_HOST}/clases/all`).then((res) => {
          //console.log(res.data.clases)
          setClases(res.data.clases)
        })
    
      }

    useEffect(() => {
        loadProfesores()
        loadClases()
    }, []);


    const handleStatus = (profesor, head, body) => {

        setStatusShow({
            show: true,
            idProfesor: profesor.rut,
            status: profesor.activo,
            title: head,
            body: body,
            callback: (data) => {
                if (data.continua) {
                    let tmpUsers = JSON.parse(JSON.stringify(baseInfo))
                    let tmpUsers2 = JSON.parse(JSON.stringify(info))
                    let index = tmpUsers.findIndex(tmp => tmp.rut === profesor.rut)
                    let index2 = tmpUsers2.findIndex(tmp => tmp.rut === profesor.rut)

                    if (index !== undefined) {
                        tmpUsers[index].activo = data.enabled ? 1 : 0;
                        tmpUsers2[index2].activo = data.enabled ? 1 : 0;
                        setBaseInfo(tmpUsers)
                        setInfo(tmpUsers2)
                        toast.success(data.message)
                    }
                } else {
                    toast.warn("Operación cancelada")
                }
                setStatusShow({ show: false })
            }

        })
    }

    const handleEdit = (profesor) => {
        console.log("show", profesor)
        setEditShow({
            show: true,
            editing: true,
            avatarimg: profesor.avatarIMG,
            clases: clases,
            profesor: profesor,
            callback: (data) => {
                if (data.continua) {
                    let tmpUsers = JSON.parse(JSON.stringify(baseInfo))
                    let tmpUsers2 = JSON.parse(JSON.stringify(info))
                    let index = tmpUsers.findIndex(tmp => tmp.rut === profesor.rut)
                    let index2 = tmpUsers2.findIndex(tmp => tmp.rut === profesor.rut)

                    if (index !== undefined) {
                        tmpUsers[index] = data.profesor;
                        tmpUsers2[index2] = data.profesor;
                        setBaseInfo(tmpUsers)
                        setInfo(tmpUsers2)
                        toast.success(data.message)
                    }
                } else {
                    toast.warn("Operación cancelada")
                }
                setEditShow({ show: false })
            }
        })
    }


    return (
        <div className="container">
            <h1>Profesores</h1>
            <div style={{ marginTop: "3rem" }}>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Avatar</th>
                            <th>Rut</th>
                            <th>Nombre y Apellido</th>
                        </tr>
                    </thead>
                    <tbody>
                        {info ? info.map((elemen, index) => {
                            return (
                                <tr key={index}>
                                    <td><Image height="50px" roundedCircle src={`${elemen.avatarIMG}`}></Image></td>
                                    <td>{elemen.rut}</td>
                                    <td>{elemen.nombre} {elemen.apellido}</td>
                                    <td>
                                        {elemen.activo ?
                                            <Button variant="warning" style={{ minWidth: 110 }} onClick={() => handleStatus(elemen, "¿Desea Deshabilitar al profesor?", `Se Deshabilitara a Profesor ${elemen.nombre} ${elemen.apellido}. Rut: ${elemen.rut}`)}>Deshabilitar</Button>
                                            :
                                            <Button variant="success" style={{ minWidth: 110 }} onClick={() => handleStatus(elemen, "¿Desea Habilitar al profesor?", `Se Habilitara a Profesor ${elemen.nombre} ${elemen.apellido}. Rut: ${elemen.rut}`)}>Habilitar</Button>
                                        }

                                        <Button onClick={() => {handleEdit(elemen);console.log(elemen)}}>Editar</Button></td>

                                </tr>
                            )
                        }) : <Spinner animation="border" role="status" />}
                    </tbody>
                </Table>

                        {/* body, status, id, show, onHide, callback */}
                <StatusProfesor
                    show={statusShow.show}
                    onHide={() => setStatusShow({ show: false })}
                    callback={statusShow.callback}
                    title={statusShow.title}
                    body={statusShow.body}
                    id={statusShow.idProfesor}
                    status={statusShow.status}
                />

                {editShow.show ?
                    <EditarProfesor
                    show={editShow.show}
                    handleClose={() => setEditShow({ show: false })}
                    callback={editShow.callback}
                    profesor={editShow.profesor}
                    editing={editShow.editing}
                    clases={editShow.clases}
                    avatarimg={editShow.avatarimg}

                    />:null}

                <ToastContainer />

            </div>
        </div>
    )
}
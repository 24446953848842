import axios from "axios"
import { useEffect, useState } from "react"
import { Form, Button, Modal, Row, Col } from "react-bootstrap"
import { toast, ToastContainer } from "react-toastify"
import { AXIOS_HOST } from "../../../config/axios.config"
import { obtainLocalStorageItem } from "../../../utilities/localStorage.utility"


export default function AñadirClase(){

    const [validated, setValidated] = useState(false);
    const [modalShow, setModalShow] = useState({show:false});

    const [newClase, setNewClase] = useState({
        nombre: "",
        descripcion: "",
        tipo: "",
        idTipo: null
    })

    const [tipos, setTipos] = useState(null)

    const obtenerTipos = async () => {
        await axios.get(`${AXIOS_HOST}/clases/alltipos`).then((tipos) => {
            setTipos(tipos.data)
            //console.log("axios tipo", tipos.data)
        })
    }

    useEffect(() => {
        obtenerTipos()
    }, [])

    const handleChange = (e) => {
        //setEditClase({...editClase, [e.target.name]: e.target.value})
        if(e.target.name === "idTipo") {
            let tipo = tipos.find((el) => el.id === parseInt(e.target.value))
            setNewClase({...newClase, tipo: tipo.tipo, idTipo: tipo.id})
        }
        else {
            setNewClase({...newClase, [e.target.name]: e.target.value})
        }
        //console.log(editClase)
    }

    const axiosAddClase = async (event) => {
        
        await axios.post(`${AXIOS_HOST}/clases/add`, newClase, { headers: { token: obtainLocalStorageItem("token")}}).then((res) => {
            event.target.reset()
            console.log(res.data)
            toast.success("Clase añadida")
            setModalShow({show:false})
        }).catch((err) => {
            console.log(err)
            toast.error("Error al añadir clase")
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            toast.error("Debe completar todos los campos")
        }
        else {
            console.warn("entre")
            setModalShow({
                show:true,
                claseNueva: newClase,
                callback: (data) => {
                    if(data.continua) {
                        axiosAddClase(event)
                    }
                    else {
                        toast.warn("Proceso cancelado")
                        setModalShow({show:false})
                    }
                }
                })

        }
        setValidated(true);
    };


    return (
        <div className="container">
            <h1>Añadir Clase</h1>

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row style={{ paddingBottom:"1rem"}}>
                    <Form.Group md={6} as={Col}>
                        <Form.Label>Nombre clase</Form.Label>
                        <Form.Control required type="text" name="nombre" onChange={handleChange}/>
                    </Form.Group>
                    <Form.Group md={6} as={Col}>
                        <Form.Label>Tipo</Form.Label>
                        <Form.Select required name="idTipo" onChange={handleChange}>
                            <option value="">Seleccionar</option>
                            {tipos && tipos.map((el) => {
                                return(
                                    <option key={el.id} value={el.id}>{el.tipo}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>
                    </Row>
                    <Form.Group style={{paddingBottom:"1.5rem"}}>
                        <Form.Label>Descripcion</Form.Label>
                        <Form.Control as="textarea" rows={2} name="descripcion" onChange={handleChange}/>
                    </Form.Group>
                    <Button type="submit">Enviar</Button>
                </Form>

                <Modal show={modalShow.show} onHide={() => setModalShow({ show: false })}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>¿Desea añadir esta clase?</p>
                        <p>Nombre: {newClase.nombre}</p>
                        <p>Tipo: {newClase.tipo}</p>
                        <p>Descripcion: {newClase.descripcion}</p>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => modalShow.callback({continua:false})}>
                            Cancelar
                        </Button>
                        <Button variant="primary" onClick={() => modalShow.callback({continua:true})}>
                            Confirmar
                        </Button>
                    </Modal.Footer>

                </Modal>

                <ToastContainer />

        </div>
    )
}
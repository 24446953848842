
import { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";


export default function EditNoticias(props) {

    const [noticiaEdit, setNoticiaEdit] = useState({
        titulo: props.noticia.titulo,
        texto: props.noticia.texto,
        idNoticia: props.noticia.idNovedad,
        activeCaption: props.noticia.activeCaption
    })





    return (
        <Modal show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
            <Modal.Header closeButton>
                <Modal.Title>Editar Noticia</Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Titulo</Form.Label>
                        <Form.Control type="text" name="titulo" value={noticiaEdit.titulo} onChange={(e) => setNoticiaEdit({ ...noticiaEdit, titulo: e.target.value })} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control as="textarea" rows={3} name="descripcion" defaultValue={noticiaEdit.texto} onChange={(e) => setNoticiaEdit({ ...noticiaEdit, texto: e.target.value })} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>¿Activar Caption?</Form.Label>
                        <Form.Check type="switch" id="custom-switch" defaultChecked={noticiaEdit.activeCaption} label={noticiaEdit.activeCaption ? "Activado" : "Desactivado"} onChange={(e) => setNoticiaEdit({ ...noticiaEdit, activeCaption: e.target.checked ? 1 : 0 })} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { props.onHide(); }}>Cerrar</Button>
                    <Button variant="primary" onClick={() => props.callback({continua:true, noticia:noticiaEdit})}>Guardar</Button>
                </Modal.Footer>
            </Form>
        </Modal>

    )

}
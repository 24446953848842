import { useState, useEffect } from 'react'
import axios from 'axios'

import { AXIOS_HOST } from '../../config/axios.config'


//import "../../publicStyle.css"
import { Button, Card, Col, Row } from 'react-bootstrap'

function CardBeneficios({beneficio,handleBeneficios}) {
    return (
        
            <Col xs={12} md={6} lg={4} xl={4} style={{ padding: "10px", maxWidth: "max-content", marginLeft: "auto", marginRight: "auto" }}>
            <Card style={{ maxWidth: "350px", aspectRatio: 2 / 2 }}>


                <Card.Img crossOrigin='anonymous' src={`${AXIOS_HOST}/filesbeneficios/${beneficio.imagen}`} style={{ objectFit: "cover", width: "100%", height: "100%" }}>

                </Card.Img>
                <Card.ImgOverlay style={{ display: "flex", flexDirection: "column", padding: 0, textAlign: "left" }}>


                    <div className='MyGlassmorphism' style={{ marginTop: "auto", borderBottomLeftRadius: "4px", borderBottomRightRadius: "4px" }}>
                        <Card.Title style={{ paddingLeft: "10px", paddingTop: "5px", color: "white", display: "inline" }}>{beneficio.nombre}</Card.Title>
                        <Card.Text className='multiline-ellipsis' style={{ paddingLeft: "5px", color: "white" }}>{beneficio.descripcion}</Card.Text>

                        <Card.Footer style={{ padding: "5px", backgroundColor: "transparent", textAlign: "right" }}>
                            <Button variant="outline-light" style={{ borderRadius: "20px", padding: "5px 10px", display: "inline-block" }} onClick={() => handleBeneficios(beneficio)} >Ver más</Button>

                        </Card.Footer>
                    </div>
                </Card.ImgOverlay>
            </Card>
            </Col>
        
    )
}

export default CardBeneficios
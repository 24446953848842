import {Card, Button} from "react-bootstrap"
import IMGDestacado from "../assets/AlumnoDestacado.jpeg"

export const CardAlumnoDestacado = () => (
    <Card style={{ maxWidth: "400px", aspectRatio: 2 / 4}}>
        <Card.Img style={{ objectFit: "cover", width: "100%", height: "100%", objectPosition: "left" }} src={IMGDestacado} />
        <Card.ImgOverlay style={{ display: "flex", flexDirection: "column", padding: 0, textAlign: "left" }}>


                                <div className='MyGlassmorphism' style={{ marginTop: "auto", borderBottomLeftRadius: "4px", borderBottomRightRadius: "4px" }}>
                                    <Card.Title style={{ paddingLeft: "10px", paddingTop: "5px", color: "white" }}>Nuestro Alumno Destacado</Card.Title>
                                    <Card.Text className='' style={{ margin: "8px", color: "white" }}>
                                    Entreno en el Parque desde hace más de 13 años porque es para mi un espacio sencillo, 
                                    inclusivo, donde todos y todas l@s profes e instructores que he conocido 
                                    han sido motivadores y buenos en lo que hacen, sin mayores alardes dan cada día su mejor energía 
                                    y alegría, por eso gracias
                                    </Card.Text>


                                </div>
            </Card.ImgOverlay>

    </Card>

)
import { Modal, Button } from "react-bootstrap"

function VerEspecifica({show, onHide, bitacora, createdAt, updatedAt}) {
  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Bitacora
            </Modal.Title>
        </Modal.Header>
        {bitacora ?
        <Modal.Body style={{ overflowWrap: 'break-word', whiteSpace: "pre-wrap", textAlign: "left", }}>
             
            <h5>Descripción:</h5>
            <p>
                {bitacora.descripcion}
            </p>
            <h5>Area:</h5>
            <h6>{bitacora.nombre} </h6>

            <h5>Creado: </h5>
            <h6>{createdAt}</h6>
            <h5>Actualizado: </h5>
            <h6>{updatedAt ? updatedAt : "Sin actualización"}</h6>

        </Modal.Body>
        : null}
        <Modal.Footer>
            <Button onClick={onHide}>Cerrar</Button>
        </Modal.Footer>
    </Modal>

  )
}

export default VerEspecifica
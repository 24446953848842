import { Modal, Button } from "react-bootstrap"

import axios from "axios"
import { obtainLocalStorageItem } from "../../../utilities/localStorage.utility"
import { AXIOS_HOST } from "../../../config/axios.config"
import { toast } from "react-toastify"


function StatusUser(props) {

    const desactivar = async () => {
        await axios.delete(`${AXIOS_HOST}/users/disable`, {data:{ id: props.user.rut, token:`${obtainLocalStorageItem("token")}` }}).then(res => {
            console.log(res)
            props.callback({continua:true, enabled:false})
        }).catch(err => {
            console.log(err)
            props.callback({continua:false})
            toast.error("Error al deshabilitar usuario")
        })
    }

    const activar = async () => {
        await axios.patch(`${AXIOS_HOST}/users/enable`, { id: props.user.rut, token:`${obtainLocalStorageItem("token")}` }).then(res => {
            console.log(res)
            props.callback({continua:true, enabled:true})
        }).catch(err => {
            console.log(err)
            props.callback({continua:false})
            toast.error("Error al habilitar usuario")
        })
    }


    const selector = () => {
        switch(props.user.status){
            case 0:
                activar()
                break;
            case 1:
                desactivar()
                break;
            default:
                props.callback({continua:false})
                break;
        }
    }


    
  return (
    <Modal
        show={props.show}
        onHide={props.onHide}>
        <Modal.Header closeButton>
            <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>{props.body}</p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => props.callback({continua:false})}>
                Cancelar
            </Button>
            <Button variant="primary" onClick={() => selector() }>
                Aceptar
            </Button>
        </Modal.Footer>
    </Modal>
  )
}

export default StatusUser
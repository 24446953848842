
import axios from "axios"
import { useEffect, useState } from "react"
import { Table } from "react-bootstrap"
import { toast, ToastContainer } from "react-toastify"
import { AXIOS_HOST } from "../../../config/axios.config"
import { obtainLocalStorageItem } from "../../../utilities/localStorage.utility"
import EditUsers from "./editUsers"
import StatusUser from "./statusUser"
import Pagination from "../../../Components/Pagination/Pagination"

export default function ShowUsers() {

    const [modalShow, setModalShow] = useState(false);
    const [modalStatusShow, setModalStatusShow] = useState(false);

    const [users, setUsers] = useState([])

    const [baseUsers, setBaseUsers] = useState([])

    //datos para paginacion
    const totalUsers = users.length

    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)

    const lastIndex = currentPage * rowsPerPage
    const firstIndex = lastIndex - rowsPerPage

    //const [user, setUser] = useState();

    useEffect(() => {
        axios.get(`${AXIOS_HOST}/users/all`).then(res => {
            console.log(res.data.users)
            setUsers(res.data.users)
            setBaseUsers(res.data.users)
        })
    }, [])

    const handleEdit = (user) => {
        setModalShow({
            show: true,
            usuario: user,
            callback: (data) => {
                setModalShow(false)
            }

        })
    }

    const handleStatus = (user, head, body) => {

        setModalStatusShow({
            show: true,
            usuario: user,
            title: head,
            body: body,
            callback: (data) => {
                if (data.continua) {
                    let tmpUsers = JSON.parse(JSON.stringify(users))
                    let index = tmpUsers.findIndex(tmp => tmp.rut === user.rut)
                    if (index !== undefined) {
                        tmpUsers[index].status = data.enabled ? 1 : 0;
                        toast.success("Usuario deshabilitado")
                        setUsers(tmpUsers)
                        setBaseUsers(tmpUsers)

                    }

                }
                else {
                    toast.warn("Operación cancelada")

                }
                setModalStatusShow(false)
            }

        })

    }



    return (
        <div className="container">

            <h2>Mostrar usuarios</h2>

            {users !== [] ? <><Table striped bordered style={{ textAlign: "left" }}>
                <thead>
                    <tr>
                        <td>Avatar</td>
                        <td>RUT</td>
                        <td>Nombre</td>
                        <td>Opciones</td>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.rut}>
                            <td>
                                <img crossOrigin="anonymous" src={`${AXIOS_HOST}/userAvatars/${user.avatar}`} alt="avatar" height={50} style={{ borderRadius: "50%", textAlign: "center", verticalAlign: "middle" }} />
                            </td>
                            <td>{user.rut}</td>
                            <td>{user.nombre} {user.apellido}</td>
                            <td>
                                <div class="d-flex flex-wrap align-items-center">
                                    <button
                                        disabled={obtainLocalStorageItem("rut") === user.rut}
                                        class="btn btn-primary mb-2 mb-md-0 mr-md-2" onClick={() => handleEdit(user)}>Editar</button>


                                    {user.status ?
                                        <button disabled={obtainLocalStorageItem("rut") === user.rut} class="btn btn-warning"
                                            onClick={() =>
                                                handleStatus(user,
                                                    "¿Desea Desactivar al usuario?",
                                                    `Se desactivara al usuario ${user.nombre} ${user.apellido}, rut: ${user.rut}`)}
                                        >Deshabilitar</button>
                                        :
                                        <button
                                            disabled={obtainLocalStorageItem("rut") === user.rut}
                                            class="btn btn-success"
                                            onClick={() =>
                                                handleStatus(user,
                                                    "¿Desea Activar al usuario?",
                                                    `Se Activar al usuario ${user.nombre} ${user.apellido}, rut: ${user.rut}`)}>Habilitar</button>
                                    }
                                </div>
                            </td>
                        </tr>
                    )).slice(firstIndex, lastIndex)}
                </tbody>


            </Table>



                {modalShow ? <EditUsers userid={modalShow.usuario} show={modalShow.show} onHide={() => setModalShow(false)} /> : <></>}
                {modalStatusShow ? <StatusUser user={modalStatusShow.usuario} title={modalStatusShow.title} body={modalStatusShow.body} show={modalStatusShow.show} onHide={() => setModalStatusShow(false)} callback={modalStatusShow.callback} /> : <></>}
            </> : "Loading..."}
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Pagination
                        rowsPerPage={rowsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalElements={totalUsers}
                    />
                </div>

            <ToastContainer />
        </div>
    )
}
import {useState, useEffect} from "react"
import axios from "axios"
import {AXIOS_HOST} from "../config/axios.config"
import HeaderV2 from "../Components/Header/headerV2"

import "./publicStyle.css"
import { Button, Card, Col, Row, Modal } from "react-bootstrap"

import CardConvenios from "../Components/beneficios/cardConvenios"

function Convenios() {

    const [convenios, setConvenios] = useState([])

    const [modalShow, setModalShow] = useState(false);
    const [modalData, setModalData] = useState({
        "nombre": "",
        "desc": "",
        "codigo": ""
    })

    const handleConvenios = ({ nombre, descripcion, imagen }) => {
        setModalShow(true)
        setModalData({
            nombre: nombre,
            descripcion: descripcion,
            image: `${AXIOS_HOST}/filesconvenios/${imagen}`,
            callback: (data) => {
                setModalShow(false)
            }
        })
    }

    useEffect(() => {
        document.title = "Parque Austral - Convenios"
        axios.get(`${AXIOS_HOST}/convenios/getActivos`).then(res => {
            console.log(res.data)
            setConvenios(res.data.convenios)
        }).catch(err => console.log(err))
    }, [])


  return (
    <div className='MyBackground'>
        <HeaderV2 />
        <div className='container' style={{ backgroundColor: "aliceblue" , height:"90vh"}}>
            <div className='row'>
            <div className='col-12'>
                <h1>Convenios</h1>
    
                <Row style={{ textAlign: "center" }}>

                    {convenios.map((convenio) => {
                        return (
                            <CardConvenios key={convenio.nombre} convenio={convenio} handleConvenios={handleConvenios} />
                        )
                    })}
                    
                </Row>
            </div>
            </div>
        </div>

        <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12} md={6} lg={6} xl={6}>
                        <img crossOrigin="anonymous" alt="imagen beneficio" src={modalData.image} style={{ width: "100%" }} />
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6}>
                        <h4>{modalData.nombre}</h4>
                        <p style={{ textAlign: "left", overflowWrap: 'break-word', whiteSpace: "pre-wrap" }}>
                            {modalData.descripcion}
                        </p>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>

    </div>

  )
}

export default Convenios
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';
import Stack from 'react-bootstrap/Stack';



import { Spacer } from '@nextui-org/react';

import 'bootstrap/dist/css/bootstrap.min.css';
import "./testS.css"

export default function Mision_Vision() {
  const [openMision, setOpenMision] = useState(false);
  const [openVision, setOpenVision] = useState(false);


  return (

    

    <div >

        <div style={{textAlign:"right", padding:"20px", paddingRight:"10vw"}}> 
        <Stack direction="horizontal" gap={3}>  

            <Button
                
                className={`ms-auto btn-mision ${openMision ? "btn-mision--active":""} `}
                size='lg'
                
                onClick={() => {setOpenMision(!openMision); setOpenVision(false)}}
                
                aria-expanded={openMision}
               
            >
                Misión
            </Button>
        
            <Button
                className={`btn-mision ${openVision ? "btn-mision--active":""}`}
                size='lg'
                onClick={() => {setOpenVision(!openVision); setOpenMision(false)}}
                aria-controls="example-collapse-text"
                aria-expanded={openVision}
                
            >
                Visión
            </Button> 
        </Stack>
        
        </div>
      
      <div style={{ paddingLeft:"3vw" }}>
        <Collapse in={openMision} dimension="height">
          <div id="example-collapse-text">
            <Card body bg='#8effe600'  style={{ maxWidth: '1000px', backgroundColor:"#8effe600", color:"white"}}>
            <div class="row g-0">
                <div class="col-md-4">
                    
                </div>
                <div class="">
                <div class="card-body">
                    <h3 class="card-title">Misión</h3>
                    <p class="card-text" style={{fontSize:20}}>“Entregar servicios en el área de la educación deportiva, recreación y salud a diferentes grupos etarios sin distinción, permitiendo el aprendizaje, recreación y bienestar en un ambiente inclusivo y familiar”</p>
                </div>
                </div>
            </div>  
            </Card>
          </div>
        </Collapse>

        <Collapse in={openVision} dimension="height">
          <div id="example-collapse-text">
            <Card body bg='#8effe600'  style={{ maxWidth: '1000px', backgroundColor:"#8effe600", color:"white"}}>
            <div class="row g-0">
                <div class="col-md-4">
                    
                </div>
                <div class="">
                <div class="card-body">
                    <h3 class="card-title">Visión</h3>
                    <p  class="card-text" style={{fontSize:20}}>“Ser la mejor experiencia en el área de recreación, deporte y bienestar del sur de Chile para todas las personas, con una gran infraestructura que brinde diversos programas y servicios de excelencia, creando conciencia de respeto al medio ambiente “</p>
                </div>
                </div>
            </div>  
            </Card>
          </div>
        </Collapse>
      </div>
    </div>
  );
}
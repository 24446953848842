import { useEffect , useState } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import Form from 'react-bootstrap/Form'
import {useNavigate} from "react-router-dom"

import { AXIOS_HOST } from '../../../config/axios.config';
import { Button, Col, Modal, Row } from 'react-bootstrap';



function CrearAreas() {

  const navigate = useNavigate()

  const [modalShow, setModalShow] = useState({show:false});

  const [area, setArea] = useState({
    nombre: "",
    descripcion: ""
  })

  const handleChange = (e) => {
    const value = e.target.value;
    setArea({
      ...area,
      [e.target.name]: value,
    });
  }

  const createArea = async (e) => {
    await axios.post(`${AXIOS_HOST}/areas/create`, area).then((res) => {

      console.log(area)
      toast.success("Area creada en BSD")
      e.target.nombre.value = ""
      e.target.descripcion.value = ""
      setModalShow(false)
      
    }).catch((err) => {
      console.log(err)
      toast.error(err.response.data.message)
      
    })

    
  }


  const [validated, setValidated] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault()

    //nose como devolver los valores de los campos a ("")
  

    const form = e.currentTarget;

    if(form.checkValidity() === false){
      e.stopPropagation()
      toast.error("Faltan campos por llenar")
      setValidated(true)
    } else {
      setModalShow({
        show: true,
        info: area, 
        callback: (() => {
          createArea(e)
      })
    })
      
    }
  }


  return (
    <>
    <div className='container'>
      <h1>Crear Areas</h1>

      <Form noValidate validated={validated} onSubmit={(e) => handleSubmit(e)} style={{textAlign:"left"}}>
        <Row>
        <Form.Group md={6} as={Col} className="mb-3" controlId="nombre">
          <Form.Label>Nombre Area</Form.Label>
          <Form.Control required type="text" placeholder="Nombre" name='nombre' onChange={(e) => handleChange(e)}/>
        </Form.Group>

        <Form.Group md={6} as={Col} className="mb-3" controlId="descripcion">
          <Form.Label>Descripción</Form.Label>
          <Form.Control required type="text" placeholder="Descripción" name='descripcion' onChange={(e) => handleChange(e)}/>
        </Form.Group>
        </Row>
        <Button variant="primary" type="submit"> Crear </Button>
        
      </Form>
    </div>
    <ToastContainer />

    <Modal show={modalShow.show} onHide={() => setModalShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmar Datos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Nombre: {area.nombre}</p>
        <p>Descripción: {area.descripcion}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setModalShow(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={modalShow.callback}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>

    </>
  )
}

export default CrearAreas
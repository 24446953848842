import { useState, useEffect } from 'react'
import axios from 'axios'

import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'

import { AXIOS_HOST } from '../../../config/axios.config'
import VerMensaje from './VerMensaje'
import MyPaginacion from '../../../Components/Pagination/Pagination';

function VerContactenos() {

  const [contactenos, setContactenos] = useState([])
  const [modalShow, setModalShow] = useState({ show: false })

  //PAGINACION
  const [currentPage, setCurrentPage] = useState(1)
  const rowsPerPage = 11

  const lastIndex = currentPage * rowsPerPage
  const firstIndex = lastIndex - rowsPerPage

  const totalContactenos = contactenos.length

  //FIN PAGINACION


  const loadData = async () => {
    await axios.get(`${AXIOS_HOST}/contactenos/get`)
      .then(res => {
        setContactenos(res.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    loadData()
  }, [])



  return (
    <div className='container'>
      <h1>Ver Mensajes</h1>
      <Table striped bordered>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Correo</th>
            <th>Telefono</th>
            <th>Mensaje</th>
            <th>Hora</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>

          {contactenos.map((contacto, index) => {
            const fechaISOCreated = new Date(contacto.createdAt)
            const horaCreated = fechaISOCreated.toLocaleDateString('es-CL', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false });
            return (
              <tr key={index}>
                <td>{contacto.nombre}</td>
                <td>{contacto.email}</td>
                <td>{contacto.telefono}</td>
                <td style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: "200px" }}>{contacto.mensaje}</td>
                <td>{horaCreated}</td>
                <td>
                  <Button onClick={() => setModalShow({ show: true, contact: contacto, createdAt: horaCreated })} >Ver</Button>
                </td>
              </tr>
            )
          }
          ).slice(firstIndex, lastIndex)}
        </tbody>

      </Table>
      <MyPaginacion
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalElements={totalContactenos}

      />

      <VerMensaje show={modalShow.show} onHide={() => setModalShow({ show: false })} contact={modalShow.contact} createdAt={modalShow.createdAt} />

    </div>
  )
}

export default VerContactenos
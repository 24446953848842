import axios from "axios"
import { useEffect, useState } from "react"
import { Button, Modal, Table } from "react-bootstrap"
import { toast, ToastContainer } from "react-toastify"
import { AXIOS_HOST } from "../../../config/axios.config"
import { obtainLocalStorageItem } from "../../../utilities/localStorage.utility"

import Pagination from "../../../Components/Pagination/Pagination"


function ShowConvenios() {

    const [modalShow, setModalShow] = useState(false);
    const [modalStatusShow, setModalStatusShow] = useState({ show: false, convenioAntes: null, callback: null });

    const [convenios, setConvenios] = useState([])
    
    //datos para paginacion
    const totalConvenios = convenios.length

    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)

    const lastIndex = currentPage * rowsPerPage
    const firstIndex = lastIndex - rowsPerPage

    const obtenerConvenios = async () => {
        await axios.get(`${AXIOS_HOST}/convenios/get`).then(res => {
            console.log(res.data)
            setConvenios(res.data.convenios)
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        obtenerConvenios()
    }, [])

    const handleStatus = (convenioAntes) => {
        setModalStatusShow({ 
            show: true, 
            convenioAntes: convenioAntes, 
            callback: async (data) => {
                if (data.continua) {
                  let operacion = data.convenio.estado ? "delete" : "patch";
                  let url = `${AXIOS_HOST}/convenios/${data.convenio.estado ? "disable" : "enable"}`;
                  let respuesta = { status: false }
        
                  if (operacion === "delete") {
                    respuesta = await axios.delete(url, {data: {id_convenio : convenioAntes.id_convenio} ,headers: { token: obtainLocalStorageItem("token") } }).then(res => {
                      //console.log(res.data);
                      toast.success(`El convenio ${data.convenio.nombre} ha sido desactivado con éxito`);
                      return res.data;
                    }).catch(err => {
                      console.log(err);
                      toast.error(`Error al desactivar el convenio ${data.convenio.nombre}`);
                    });
                  } else {
                    respuesta = await axios.patch(url, convenioAntes, { headers: { token: obtainLocalStorageItem("token") } }).then(res => {
                      //console.log(res.data);
                      toast.success(`El convenio ${data.convenio.nombre} ha sido activado con éxito`);
                      return res.data;
                    }).catch(err => {
                      console.log(err);
                      toast.error(`Error al activar el convenio ${data.convenio.nombre}`);
                    });
                  }
                  if (respuesta.status === true) {
                    let tmpconvenios = JSON.parse(JSON.stringify(convenios))
                    tmpconvenios = tmpconvenios.map(convenio => {
                      if (convenio.id_convenio === data.convenio.id_convenio) {
                        convenio.estado = !data.convenio.estado
        
                      }
                      return convenio
                    })

                    setConvenios(tmpconvenios)
                    
                  }
                  setModalStatusShow(false)
                }
              } })
    }






    

  return (
    <div className="container">
        <h2>Convenios</h2>
        <Table striped bordered style={{ textAlign: "left" }}>
            <thead>
            <tr>
                <th>Nombre</th>
                <th>Descripción</th>
                <th>Estado</th>
                <th>Acciones</th>
            </tr>
            </thead>
            <tbody>
                {convenios.slice(firstIndex, lastIndex).map((convenio, index) => {
                    return (
                        <tr key={index}>
                            <td>{convenio.nombre}</td>
                            <td>{convenio.descripcion}</td>
                            <td>{convenio.estado ? "Activo" : "Inactivo"}</td>
                            <td>
                                <Button variant="primary" onClick={() => handleStatus(convenio)}>{convenio.estado ? "Desactivar" : "Activar"}</Button>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
        <Pagination
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalElements={totalConvenios}
   
        />
        <Modal
            show={modalStatusShow.show}
            onHide={() => setModalStatusShow(false)}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
            <Modal.Title>Confirmación</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {modalStatusShow.convenioAntes ? (
                <p>
                ¿Está seguro que desea{" "}
                {modalStatusShow.convenioAntes.estado ? "desactivar" : "activar"}{" "}
                el convenio {modalStatusShow.convenioAntes.nombre}?
                </p>
            ) : (
                <p>¿Está seguro que desea realizar esta acción?</p>
            )}
            </Modal.Body>
            <Modal.Footer>
            <Button
                variant="secondary"
                onClick={() =>
                modalStatusShow.callback({
                    continua: false,
                    convenio: modalStatusShow.convenioAntes,
                })
                }
            >
                Cancelar
            </Button>
            <Button
                variant="primary"
                onClick={() =>
                modalStatusShow.callback({
                    continua: true,
                    convenio: modalStatusShow.convenioAntes,
                })
                }
            >
                Continuar
            </Button>
            </Modal.Footer>
        </Modal>
        <ToastContainer />


        
    </div>
  )
}

export default ShowConvenios
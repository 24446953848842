import { Modal, Button, Col, Row, Form, Dropdown, Popover } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "axios";
import { AXIOS_HOST } from "../../../config/axios.config";
import { toast, ToastContainer } from "react-toastify";
import { obtainLocalStorageItem } from "../../../utilities/localStorage.utility";


function CrearBeneficiosTipos({ show, onhide, callback }) {

  const [validated, setValidated] = useState(false);
  const [modalConfirmacion, setModalConfirmacion] = useState({ show: false, callback: false })


  const [beneficiosTipos, setBeneficiosTipos] = useState({
    nombre: ""
  })


  function handleChange(e) {
    const value = e.target.value;
    setBeneficiosTipos({
      ...beneficiosTipos,
      [e.target.name]: value
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      toast.error("Debe completar todos los campos")
    } else {
      setModalConfirmacion({
        show: true,
        callback: () => {
          axios.post(`${AXIOS_HOST}/beneficios/tipos/create`, beneficiosTipos, { headers: { token: obtainLocalStorageItem("token") } }).then(res => {
            console.log(res.data)
            callback(beneficiosTipos)
            toast.success("Tipo de beneficio creado con éxito")
          }).catch(err => {
            console.log(err)
            toast.error("Error al crear tipo de beneficio")
          })
        }
      })
    }

    setValidated(true);

  }

  return (
    <>
      <Modal
        show={show}
        onHide={onhide}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Crear Tipo de Beneficio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control required type="text" placeholder="Nombre" name="nombre" value={beneficiosTipos.nombre} onChange={handleChange} />
              <Form.Control.Feedback type="invalid">
                Ingrese nombre
              </Form.Control.Feedback>
            </Form.Group>

            <div className="d-flex justify-content-end" style={{ paddingTop: "3%" }}>
              <Button variant="secondary" onClick={onhide} style={{ margin: "1%" }}>
                Cerrar
              </Button>
              <Button variant="primary" type="submit" style={{ margin: "1%" }}>
                Crear
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <ToastContainer />

      <Modal
        show={modalConfirmacion.show}
        onHide={() => setModalConfirmacion({ show: false, confirmation: false })}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Está seguro que desea crear el tipo de beneficio?</p>
          <div className="d-flex justify-content-end" style={{ paddingTop: "3%" }}>
            <Button variant="secondary" onClick={() => setModalConfirmacion({ show: false, confirmation: false })} style={{ margin: "1%" }}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={() => {modalConfirmacion.callback(); setModalConfirmacion({show:false, callback: false})}} style={{ margin: "1%" }}>
              Confirmar
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CrearBeneficiosTipos
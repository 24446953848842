import { Modal } from "react-bootstrap"

export default function EditUsers(props) {
    console.log(props.userid)
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton> <Modal.Title>Edit Users</Modal.Title> </Modal.Header>

        </Modal>
    )
}


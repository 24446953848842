
import axios from "axios"
import { useEffect, useState } from "react"
import { Button, Table } from "react-bootstrap"
import { toast, ToastContainer } from "react-toastify"
import { AXIOS_HOST } from "../../../config/axios.config"
import { obtainLocalStorageItem } from "../../../utilities/localStorage.utility"

import Pagination from "../../../Components/Pagination/Pagination"
import CrearBeneficiosTipos from "./crearBeneficiosTipos"

export default function ShowBeneficiosTipos() {

    const [modalShow, setModalShow] = useState(false);
    const [modalStatusShow, setModalStatusShow] = useState(false);
    const [modalCreateShow, setModalCreateShow] = useState(false);

    const [tipos, setTipos] = useState([])

    const [baseTipos, setBaseTipos] = useState([])

    //datos para paginacion
    const totaltipos = tipos.length

    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)

    const lastIndex = currentPage * rowsPerPage
    const firstIndex = lastIndex - rowsPerPage

    //const [tipo, settipo] = useState();

    useEffect(() => {
        axios.get(`${AXIOS_HOST}/beneficios/tipos`).then(res => {
            console.log(res.data.tiposBeneficios)
            setTipos(res.data.tiposBeneficios)
            setBaseTipos(res.data.tiposBeneficios)
        })
    }, [])

    const handleCreate = () => {
        setModalCreateShow({
            show: true,
            callback: (data) => {
                setBaseTipos([...baseTipos, { id_tipoBeneficio: baseTipos[-1] + 1, nombre: data.nombre, created_at: new Date() }])
                setModalCreateShow(false)
            }
        })
    }

    const handleEdit = (tipo) => {
        setModalShow({
            show: true,
            usuario: tipo,
            callback: (data) => {
                setModalShow(false)
            }

        })
    }


    const handleStatus = (tipo, head, body) => {

        setModalStatusShow({
            show: true,
            usuario: tipo,
            title: head,
            body: body,
            callback: (data) => {
                if (data.continua) {
                    let tmptipos = JSON.parse(JSON.stringify(tipos))
                    let index = tmptipos.findIndex(tmp => tmp.rut === tipo.rut)
                    if (index !== undefined) {
                        tmptipos[index].status = data.enabled ? 1 : 0;
                        toast.success("Usuario deshabilitado")
                        setTipos(tmptipos)
                        setBaseTipos(tmptipos)

                    }

                }
                else {
                    toast.warn("Operación cancelada")

                }
                setModalStatusShow(false)
            }

        })

    }



    return (
        <div className="container">

            <h2>Mostrar Tipos de Beneficios</h2>
            <div style={{ display: "flex", justifyContent: "flex-start", padding: "1%" }}>
                <Button variant="primary" onClick={handleCreate}>Agregar</Button>
            </div>

            {tipos !== [] ?
                <>
                    <Table striped bordered style={{ textAlign: "left" }}>
                        <thead>
                            <tr>
                                <td>ID</td>
                                <td>Nombre</td>
                                <td>Fecha creación</td>
                                <td>Acciones</td>
                            </tr>
                        </thead>
                        <tbody>
                            {tipos.map(tipo => {
                                const fechaISO = new Date(tipo.created_at)
                                const hora = fechaISO.toLocaleDateString('es-CL', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false });

                                return (
                                    <tr key={tipo.id_tipoBeneficio}>
                                        <td>
                                            {tipo.id_tipoBeneficio}
                                        </td>
                                        <td>{tipo.nombre}</td>
                                        <td>{hora}</td>
                                        <td>
                                            <div class="d-flex flex-wrap align-items-center">
                                                <button
                                                    class="btn btn-primary mb-2 mb-md-0 mr-md-2"
                                                    onClick={() => handleEdit(tipo)}>Editar</button>

                                            </div>
                                        </td>
                                    </tr>
                                )
                            }).slice(firstIndex, lastIndex)}
                        </tbody>


                    </Table>


                </> : "Loading..."}
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Pagination
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalElements={totaltipos}
                />
            </div>

            <ToastContainer />

            <CrearBeneficiosTipos
                show={modalCreateShow.show}
                onhide={() => setModalCreateShow({ show: false })}
                callback={modalCreateShow.callback}
            />
        </div>
    )
}
import TestHeader from "../Components/Header/headerV2";
import axios from "axios"
import { AXIOS_HOST } from "../config/axios.config"
import { useState, useEffect } from "react"
import { Card, Col, Row } from "react-bootstrap";


import "./publicStyle.css"
import MyPaginacion from "../Components/Pagination/Pagination";
import { useNavigate } from "react-router-dom";


export default function Noticias() {
    const navigate = useNavigate()

    const [noticias, setNoticias] = useState([])
    //PAGINACION
    const [currentPage, setCurrentPage] = useState(1)
    const rowsPerPage = 4


    const lastIndex = currentPage * rowsPerPage
    const firstIndex = lastIndex - rowsPerPage

    const totalNoticias = noticias.length
    
    //FIN PAGINACION




    const loadNoticias = async () => {
        await axios.get(`${AXIOS_HOST}/noticias/activas`).then((res) => {
            setNoticias(res.data)
        })
    }

    useEffect(() => {
        loadNoticias()
    }, [])


    return (
        <div className="MyBackground">
            <TestHeader />
            <div className="container" style={{ backgroundColor: "aliceblue", minHeight: "100vh" }}>
                <h2>Noticias</h2>
                <p>Observe las diferentes novedades que han ocurrido en nuestra comunidad</p>

                <hr />

                {noticias.length !== 0 ?
                noticias.map((noticia) => {
                    return (
                        <>
                        <div className="clean-blog-post" style={{ textAlign: "left", backgroundColor:"azure"}}>
                            <div className="row">
                                <div className="col-lg-5"><img alt="Imagen de portada" className="rounded img-fluid" src={`${AXIOS_HOST}/filesnoticias/${noticia.archivos.filter((img) => { return Number(img.isPortada) === 1 })[0].nombreFile}`} crossOrigin="anonymous" style={{ maxHeight: "350px", aspectRatio: "2/1" }} /></div>
                                <div className="col-lg-7">
                                    <h3>{noticia.titulo}</h3>
                                    <div className="info">
                                        <span className="text-muted">
                                            {new Date(noticia.createdAt).toLocaleDateString("es-CL", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false })}
                                        </span>
                                    </div>
                                    <p className="multiline-ellipsis" style={{whiteSpace: "pre-wrap", maxHeight:"130px"}}>{noticia.texto}</p>
                                    <button className="btn btn-outline-primary btn-sm mb-3" type="button" onClick={() => navigate(`./${noticia.idNovedad}`)}>Seguir Leyendo</button>
                                </div>
                            </div>
                        </div>
                        <hr />
                        </>
                    )
                }
                ).slice(firstIndex, lastIndex): "Cargando..."}

                <MyPaginacion
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalElements={totalNoticias}
                    
                />
                
                

            </div>
        </div>
    )
}
import { useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Modal from 'react-bootstrap/Modal'
import { AXIOS_HOST } from '../../../config/axios.config';
import { obtainLocalStorageItem } from '../../../utilities/localStorage.utility';


export default function EditarBitacora({ areas, bitacora, handleUpdatedBitacoras, bitacoras }) {

    const [modalShow, setModalShow] = useState({ show: false })

    const [editing, setEditing] = useState({
        idBitacora: bitacora.idBitacora,
        idArea: bitacora.idArea || undefined,
        descripcion: `${bitacora.descripcion}\n(update ${new Date().toLocaleString()}))\n`,
        idUsuario: obtainLocalStorageItem("rut"),
    })

    const [validated, setValidated] = useState(false);

    const handleDescChange = (e) => {
        
        const value = e.target.value;
        setEditing({
            ...editing,
            [e.target.name]: `${bitacora.descripcion}\n(update ${new Date().toLocaleString()})\n ${value}`,
        });
    }
    
    const handleAreaChange = (e) => {
        
        const value = e.target.value;
        setEditing({
            ...editing,
            [e.target.name]: value,
        });
    }

    const handleUpdateLocal = () => {
        let tmpBitacoras = JSON.parse(JSON.stringify(bitacoras))
        let index = tmpBitacoras.findIndex((tmp) => tmp.idBitacora === bitacora.idBitacora)
        if (index !== undefined) {
            tmpBitacoras[index].idArea = editing.idArea
            tmpBitacoras[index].descripcion = editing.descripcion
            tmpBitacoras[index].nombre = areas.filter(function (element) {
                                                return (element.idAreas === Number(editing.idArea))
                                            })[0].nombre
            handleUpdatedBitacoras(tmpBitacoras)
        }

    }

    const updateBitacora = async (e) => {
        e.preventDefault()
        await axios.put(`${AXIOS_HOST}/bitacora/edit/`, editing).then((res) => {
            console.log(res.data)
            e.target.descripcion.value = ""
            e.target.idArea.value = null
            e.target.reset()
            setModalShow({ show: false })
            handleUpdateLocal()
            toast.success("Bitacora actualizada correctamente")
        }).catch((err) => {
            setModalShow({ show: false })
            console.log(err)
            toast.error(err.response.data.message)
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            toast.warn("Para actualizar debe completar el campo de descripción")
            event.stopPropagation();
            setValidated(true);
        } else {
            toast.info("Esperando para actualizar Bitácora...")
            setModalShow({ show: true, callback: () => updateBitacora(event) })
        }
    };

    return (
        <div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>

                    <Form.Group className="mb-3" as={Col} md={6} controlId="idDescripcion">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control required as="textarea" placeholder="Agregar a Descripción" onChange={handleDescChange} name='descripcion' />
                    </Form.Group>


                    <Form.Group className="mb-3" as={Col} md={6} controlId="idArea">
                        <Form.Label>Area (Opcional)</Form.Label>
                        <Form.Select name="idArea" defaultValue={bitacora.idAreas} onChange={handleAreaChange} >
                            <option value="" >Seleccione una opcion (Se conserva la anterior si no modifica nada)</option>
                            {areas.map((area) => {

                                return <option key={area.idAreas} value={area.idAreas}>{area.nombre}</option>
                            })}

                        </Form.Select>
                    </Form.Group>

                </Row>

                <Button variant="primary" type="submit" > Actualizar </Button>
            </Form>
            <ToastContainer />

            <Modal show={modalShow.show} onHide={() => setModalShow({ show: false })}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Está seguro que desea modificar la bitácora?
                    <p style={{ overflowWrap: 'break-word', whiteSpace: "pre-wrap", textAlign: "left", }}>Descripción:<br />{editing.descripcion}</p>
                    <p>Area: {areas && editing.idArea !== undefined ? areas.filter(function (element) {
                                
                                return (element.idAreas === Number(editing.idArea))
                            })[0].nombre : null}
                    </p>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalShow({ show: false })}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={() => modalShow.callback()}>
                        Actualizar
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )

}


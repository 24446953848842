
import Background from "./background"

import 'bootstrap/dist/css/bootstrap.css';
import "./style.css"

import { Card, Button, Row, Col } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from "react";
import { obtainLocalStorageItem, persistLocalStorage } from "../../utilities/localStorage.utility";
import {useNavigate  , Navigate, Outlet } from 'react-router-dom';

import axios from "axios";
import { AXIOS_HOST } from "../../config/axios.config";
import { toast, ToastContainer } from "react-toastify";


function Login() {

    const navigate = useNavigate()

    const [imgData, setImgData] = useState(null)

    const [userState, setUserState] = useState({
        id:"",
        password:""
    })

    function formatRut(event) {
        const rutInput = event.target;
        let rut = rutInput.value.replace(/[^\dkK]/g, '');
        const dv = rut.slice(-1)
        rut = rut.slice(0,-1)
        
        if (rut.length > 6) {
            rut = `${rut.slice(0, -6)}.${rut.slice(-6, -3)}.${rut.slice(-3)}`;
          } else if (rut.length > 3) {
            rut = `${rut.slice(0, -3)}.${rut.slice(-3)}`;
          }
          rutInput.value = `${rut}-${dv}`;
      }

    const handleChange = (e) => {
        const value = e.target.value;
        setUserState({
          ...userState,
          [e.target.name]: value,
        });
    }

    const [validated, setValidated] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.currentTarget;

        if (form.checkValidity() === false){
            e.stopPropagation();
            setValidated(true);
        }
        else{

            console.log(userState)
            login()

        }
    }

    const login = async () => {
        console.log(userState)
        await axios.post(`${AXIOS_HOST}/users/login`, userState).then(async (res) => {
            console.log("conseguido", res.data.token)
            
            persistLocalStorage("token", res.data.token)
            navigate("/dashboard")
        }).catch((err) => {
            console.log(err.response)
            toast.error(err.response.data.message.replace("Error: ",""))
        })

    }



    return (
        <div className="complete">
            <div class="flex-container">

                <div class="background"><Background /></div>

                <div class="content">
                    <Card style={{ width: '40rem', maxWidth: "90vw" }}>

                        <Row>
                            <Col style={{ textAlign: "center" }} md={5}>
                                <img width="100%" height="100%" style={{ objectFit: "cover" }} src={`https://i.pravatar.cc/1000`} alt="" />
                            </Col>
                            <Col md={7}>
                                <Card.Body>
                                    <Card.Title>Log in</Card.Title>
                                    <Card.Text>
                                        Ingrese sus datos de autenticación
                                    </Card.Text>
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <Form.Group className="mb-3" controlId="rut">
                                            <Form.Label>RUT</Form.Label>
                                            <Form.Control required type="text" name="id" placeholder="Ingrese Rut" onInput={formatRut} onChange={handleChange}/>
                                            <Form.Control.Feedback type="invalid">
                            Ingrese Rut
                        </Form.Control.Feedback>

                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="password">
                                            <Form.Label>Contraseña</Form.Label>
                                            <Form.Control required type="password" name="password" placeholder="Contraseña" onChange={handleChange}/>
                                            <Form.Control.Feedback type="invalid">
                            Debe ingresar la contraseña
                        </Form.Control.Feedback>
                                        </Form.Group>
                                        
                                        <Button variant="primary" type="submit">
                                            Ingresar
                                        </Button>
                                    </Form>
                                    
                                </Card.Body>

                            </Col>
                        </Row>


                    </Card>
                </div>

            </div>
            <ToastContainer/>
        </div>
    )
}

export default Login
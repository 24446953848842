import {useNavigate  , Navigate, Outlet } from 'react-router-dom';

import axios from 'axios';
import { AXIOS_HOST } from '../config/axios.config';
import { PrivateRoutes, PublicRoutes } from '../routes/routes';
import { useEffect, useRef, useState } from 'react';
import { obtainLocalStorageItem } from '../utilities/localStorage.utility';


const PrivateValidationFragment = <Outlet />;
const PublicValidationFragment = (
  <Navigate replace to={PublicRoutes.LOGIN} />
);


const AuthGuard = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();
  
    useEffect(() => {
      const checkAuth = async () => {
        try {
          const token = localStorage.getItem("token");
          if (!token) {
            throw new Error("No token found");
          }
          const response = await axios.get(`${AXIOS_HOST}/jwt/authenticate`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          if (response.status !== 200) {
            throw new Error("Invalid token");
          }
          //console.log("logrado")
          setIsAuthenticated(true);
        } catch (error) {
          console.error(error);
          navigate("/login");
        }
      };
      checkAuth();
    }, [navigate]);
  
    return isAuthenticated ? PrivateValidationFragment : null;
  };
  
  export default AuthGuard;

import { Modal, Button, Row, Col } from "react-bootstrap"

function ModalInformacion({ show, onHide, profesor }) {
  return (
    <>
      {profesor ?
        <Modal show={show} onHide={onHide} size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title>Información de {profesor.nombre} {profesor.apellido}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={12} md={6} lg={6} xl={6}>
                <img src={profesor.avatarIMG} alt="avatar" style={{ width: "100%", height:"100%", objectFit: "cover" }} />
              </Col>
              <Col xs={12} md={6} lg={6} xl={6}>
                
                <b>Clases:</b>
                <ul style={{ listStyleType: "disc" }}>
                  {profesor.clases != null ?
                    
                      profesor.clases.map((clase, index) => {

                        return <li key={index}>{clase.clase}</li>
                      })
                    
                    : "Sin Clases Asignadas"}

                </ul>
              </Col>
              <Col xs={12}>
                <b>Descripción:</b>
                <p style={{ textAlign: "left", overflowWrap: 'break-word', whiteSpace: "pre-wrap" }}>{profesor.descrip}</p>
              </Col>

            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
        :
        null
      }
    </>

  )
}

export default ModalInformacion